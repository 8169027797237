import mergeObjects from 'merge-objects';

import api from './index';
import ApiClient from './client';
import { cleanEmptyFromObject } from '../utility/formaters';

let cachedRequests = [];

const middleware = ({ dispatch, getState }) => next => async (action) => {
  if (!action[ApiClient.ACTION_KEY]) {
    next(action);
    return;
  }

  const {
    method,
    params = {},
    requestAction,
    successAction,
    errorAction,
    transformResponse,
    afterSuccess,
    useCache,
    needParamsFormat = true,
    errorHandler,
  } = action;

  try {
    const requestParams = (params instanceof Object && needParamsFormat)
      ? cleanEmptyFromObject(params)
      : params;
    const cacheKey = JSON.stringify({ params: requestParams, method });

    if (useCache) {
      if (cachedRequests.includes(cacheKey)) {
        return;
      }

      cachedRequests.push(cacheKey);
    }
    dispatch({ type: requestAction });
    const { limit } = requestParams;
    let response = {};

    if (limit > 50) {
      const nrOfRequests = Math.ceil(limit / 50);
      const promises = [...new Array(nrOfRequests)].map((el, index) => {
        const newParams = { ...requestParams };
        newParams.offset = index * 50;
        newParams.limit = 50;
        return api[method](newParams);
      });

      const resolvedPromises = await Promise.all(promises);
      resolvedPromises.forEach((el) => {
        response = mergeObjects(response, el);
      });
    } else {
      response = await api[method](requestParams);
    }

    cachedRequests = cachedRequests.filter(key => key !== cacheKey);

    dispatch({
      type: successAction,
      payload: transformResponse ? transformResponse(response) : response,
    });
    if (afterSuccess) {
      afterSuccess(dispatch, response);
    }
  } catch (error) {
    if (errorHandler) {
      const { data: { metadata, error: err } } = error.response;
      const formatedError = { ...error };

      if (metadata) {
        if (typeof metadata === 'string') {
          const state = await getState();
          const { currLang, data } = state.translations;
          const lang = currLang || localStorage.getItem('i18n-locale') || 'en';
          const meta = metadata.toLowerCase();
          const message = (((data[lang] || {}).desktop || {}).errors_messages || {})[meta];

          formatedError.response.data.error = message || metadata;
        } else if (metadata.length) {
          formatedError.response.data.error = metadata.reduce((acc, { message }) => (`${acc}${message}. `), '');
        } else if (metadata.message) {
          formatedError.response.data.error = metadata.message;
        } else if (metadata.msg) {
          formatedError.response.data.error = metadata.msg;
        } else {
          formatedError.response.data.error = Object.keys(metadata).reduce((acc, key) => (`${acc}${key} - ${metadata[key].message}. `), '');
        }
      } else if (err && typeof err === 'string') {
        const state = await getState();
        const { currLang, data } = state.translations;
        const lang = currLang || localStorage.getItem('i18n-locale') || 'en';
        const meta = err.toLowerCase();
        const message = (((data[lang] || {}).desktop || {}).errors_messages || {})[meta];

        formatedError.response.data.error = message || err;
      }

      errorHandler(dispatch, formatedError);
    } else {
      dispatch({
        type: errorAction,
        error,
      });
    }
  }
};

export default middleware;
