import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@material-ui/core';

const ItemRenderer = (props) => {
  const {
    index,
    style,
    data: {
      options,
      getItemProps,
      highlightedIndex,
      renderListItem,
    },
  } = props;
  const item = options[index];

  return (
    <div style={style}>
      <MenuItem
        {...getItemProps({
          item,
          index,
          selected: highlightedIndex === index,
          disabled: (item || {}).status ? (item || {}).status.toLowerCase() !== 'active' : false
        })}
      >
        {renderListItem(item)}
      </MenuItem>
    </div>
  );
};

ItemRenderer.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ItemRenderer;
