import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

import noLogo from '../../media/no-cover.png';

const LogoUploader = ({
  handleImage, image, imageError, disabled
}) => {
  let inputRef = null;
  const getImage = () => {
    if (image) {
      if (image.preview) {
        return image.preview;
      }
      return image;
    }
    return noLogo;
  };

  const language = localStorage.getItem('i18n-locale') || 'en';

  return (
    <div className="logo-uploader">
      <div
        className={image ? 'logo' : 'default-logo'}
        style={{ backgroundImage: `url(${getImage()})` }}
      />
      {!disabled && (
        <div
          className={`upload-btn ${language !== 'en' ? 'hebrew' : ''}`}
          onClick={() => {
            inputRef.click();
          }}
        >
          <i className="material-icons">
            cloud_upload
          </i>
          {image
            ? <Translate value="desktop.common.repalce_label" />
            : <Translate value="desktop.common.place_photo_label" />}
        </div>
      )}
      {imageError && (
        <div className="error-message">{imageError}</div>
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleImage}
        ref={(ref) => { inputRef = ref; }}
        id="logo-upload"
        accept=".png,.jpg,.jpeg,.svg"
      />
    </div>
  );
};

LogoUploader.propTypes = {
  imageError: PropTypes.string,
  handleImage: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)])
};

LogoUploader.defaultProps = {
  image: null,
  imageError: ''
};

export default LogoUploader;
