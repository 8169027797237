import React from 'react';
import { Translate, I18n } from 'react-i18nify';

export const countryList = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia (Plurinational State of)', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czechia', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Holy See', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran (Islamic Republic of)', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea (Democratic People's Republic of)", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia (Federated States of)', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Surilabel', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom of Great Britain and Northern Ireland', value: 'GB' },
  { label: 'United States of America', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela (Bolivarian Republic of)', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands (British)', value: 'VG' },
  { label: 'Virgin Islands (U.S.)', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' }
];

export const maxFileSize = 5 * 1024 * 1024;

export const OPERATOR_STATUSES = {
  ACTIVE: 'Active',
  INACTIVE: 'Not Active',
};

export const OPERATOR_STATUSES_FORMATED = {
  [OPERATOR_STATUSES.ACTIVE]: () => I18n.t('desktop.selects.active'),
  [OPERATOR_STATUSES.INACTIVE]: () => I18n.t('desktop.selects.not_active'),
};

export const operatorStatuses = [
  {
    label: OPERATOR_STATUSES_FORMATED[OPERATOR_STATUSES.ACTIVE],
    value: OPERATOR_STATUSES.ACTIVE
  },
  {
    label: OPERATOR_STATUSES_FORMATED[OPERATOR_STATUSES.INACTIVE],
    value: OPERATOR_STATUSES.INACTIVE
  }
];

export const PROFILE_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DEPORTED: 'deported',
};

export const PROFILE_STATUSES_FORMATED = {
  [PROFILE_STATUSES.ACTIVE]: () => I18n.t('desktop.selects.active'),
  [PROFILE_STATUSES.INACTIVE]: () => I18n.t('desktop.selects.inactive'),
  [PROFILE_STATUSES.DEPORTED]: () => I18n.t('desktop.selects.deported'),
};

export const profileStatuses = [
  {
    label: PROFILE_STATUSES_FORMATED[PROFILE_STATUSES.ACTIVE],
    value: PROFILE_STATUSES.ACTIVE
  },
  {
    label: PROFILE_STATUSES_FORMATED[PROFILE_STATUSES.INACTIVE],
    value: PROFILE_STATUSES.INACTIVE
  },
  {
    label: PROFILE_STATUSES_FORMATED[PROFILE_STATUSES.DEPORTED],
    value: PROFILE_STATUSES.DEPORTED
  },
];

export const PAYMENT_TYPES = {
  SUBSCRIPTION: 'subscription',
  PAY_PER_GAME: 'payPerGame',
  REFUND: 'refund',
  EXTEND_GAME: 'extendGame',
  PENALTY: 'penalty'
};

export const PAYMENT_TYPES_FORMATED = {
  [PAYMENT_TYPES.SUBSCRIPTION]: () => I18n.t('desktop.selects.subscription'),
  [PAYMENT_TYPES.PAY_PER_GAME]: () => I18n.t('desktop.selects.pay_per_game'),
  [PAYMENT_TYPES.REFUND]: () => I18n.t('desktop.selects.refund'),
  [PAYMENT_TYPES.EXTEND_GAME]: () => I18n.t('desktop.selects.extend_game'),
  [PAYMENT_TYPES.PENALTY]: () => I18n.t('desktop.selects.penalty')
};

export const SUB_PLAN_TYPE = {
  PAY_PER_GAME: 'payPerGame',
  RECURRING: 'recurring',
  FREE: 'free'
};

export const SUB_PLAN_FORMATED = {
  [SUB_PLAN_TYPE.PAY_PER_GAME]: () => I18n.t('desktop.selects.pay_per_game'),
  [SUB_PLAN_TYPE.FREE]: () => I18n.t('desktop.selects.free'),
  [SUB_PLAN_TYPE.RECURRING]: () => I18n.t('desktop.selects.recurring'),
};

export const subPlansSelect = [
  {
    label: SUB_PLAN_FORMATED[SUB_PLAN_TYPE.PAY_PER_GAME],
    value: SUB_PLAN_TYPE.PAY_PER_GAME
  },
  {
    label: SUB_PLAN_FORMATED[SUB_PLAN_TYPE.RECURRING],
    value: SUB_PLAN_TYPE.RECURRING
  },
  {
    label: SUB_PLAN_FORMATED[SUB_PLAN_TYPE.FREE],
    value: SUB_PLAN_TYPE.FREE
  },
];

export const SUB_PLAN_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PAUSED: 'paused'
};

export const SUB_PLAN_STATUS_FORMATED = {
  [SUB_PLAN_STATUS.ACTIVE]: () => I18n.t('desktop.selects.active'),
  [SUB_PLAN_STATUS.INACTIVE]: () => I18n.t('desktop.selects.inactive'),
  [SUB_PLAN_STATUS.PAUSED]: () => I18n.t('desktop.selects.paused')
};

export const SUB_PLAN_STATUS_SELECT = [
  {
    label: SUB_PLAN_STATUS_FORMATED[SUB_PLAN_STATUS.ACTIVE],
    value: SUB_PLAN_STATUS.ACTIVE
  },
  {
    label: SUB_PLAN_STATUS_FORMATED[SUB_PLAN_STATUS.INACTIVE],
    value: SUB_PLAN_STATUS.INACTIVE
  },
  {
    label: SUB_PLAN_STATUS_FORMATED[SUB_PLAN_STATUS.PAUSED],
    value: SUB_PLAN_STATUS.PAUSED
  },
];
export const RECURRENT_TYPE = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  QUARTER: 'quarter'
};

export const currencies = [
  { label: () => I18n.t('desktop.selects.usd_label'), value: 'usd' },
  { label: () => I18n.t('desktop.selects.nis_label'), value: 'ils' },
  { label: () => I18n.t('desktop.selects.eur_label'), value: 'eur' },
];

export const RECURRENT_TYPE_SELECT = [
  {
    label: () => I18n.t('desktop.selects.day'),
    value: RECURRENT_TYPE.DAY
  },
  {
    label: () => I18n.t('desktop.selects.week'),
    value: RECURRENT_TYPE.WEEK
  },
  {
    label: () => I18n.t('desktop.selects.month'),
    value: RECURRENT_TYPE.MONTH
  },
  {
    label: () => I18n.t('desktop.selects.quarter'),
    value: RECURRENT_TYPE.QUARTER
  },
  {
    label: () => I18n.t('desktop.selects.year'),
    value: RECURRENT_TYPE.YEAR
  },
];

export const optionsForSystemParameters = [
  {
    value: '10min',
    label: '10min'
  },
  {
    value: '30min',
    label: '30min'
  },
  {
    value: '1h',
    label: '1h'
  },
  {
    value: '2h',
    label: '2h'
  },
  {
    value: '5h',
    label: '5h'
  },
  {
    value: '10h',
    label: '10h'
  }
];

export const labelsForSystemParameters = {
  minimumAge: <Translate value="desktop.parameters.minimum_age_label" />,
};

export const languageSelect = [
  { code: 'us', value: 'en' },
  { code: 'il', value: 'he' },
];

export const ASSET_STATUSES = {
  CREATED: 'created',
  ORDERLY: 'orderly',
  NOT_IN_USE: 'not_in_use',
  FAULT: 'fault',
  IN_REPAIR: 'in_repair',
  NOT_FIXABLE: 'not_fixable'
};

export const ASSET_STATUSES_FORMATED = {
  [ASSET_STATUSES.CREATED]: () => I18n.t('desktop.selects.created'),
  [ASSET_STATUSES.ORDERLY]: () => I18n.t('desktop.selects.orderly'),
  [ASSET_STATUSES.NOT_IN_USE]: () => I18n.t('desktop.selects.not_in_use'),
  [ASSET_STATUSES.FAULT]: () => I18n.t('desktop.selects.fault'),
  [ASSET_STATUSES.IN_REPAIR]: () => I18n.t('desktop.selects.in_repair'),
  [ASSET_STATUSES.NOT_FIXABLE]: () => I18n.t('desktop.selects.not_fixable')
};

export const assetSatus = [
  {
    label: ASSET_STATUSES_FORMATED[ASSET_STATUSES.IN_REPAIR],
    value: ASSET_STATUSES.IN_REPAIR
  },
  {
    label: ASSET_STATUSES_FORMATED[ASSET_STATUSES.ORDERLY],
    value: ASSET_STATUSES.ORDERLY
  },
  {
    label: ASSET_STATUSES_FORMATED[ASSET_STATUSES.NOT_IN_USE],
    value: ASSET_STATUSES.NOT_IN_USE
  },
  {
    label: ASSET_STATUSES_FORMATED[ASSET_STATUSES.FAULT],
    value: ASSET_STATUSES.FAULT
  },
  {
    label: ASSET_STATUSES_FORMATED[ASSET_STATUSES.NOT_FIXABLE],
    value: ASSET_STATUSES.NOT_FIXABLE
  },
];

export const namespacesSelectList = ['user', 'network', 'payments', 'court', 'game', 'admin'];

export const roles = {
  ADMIN: 1,
  SYSTEM_MANAGER: 10,
  COURT_GROUP_MANAGER: 20,
  PLAYER: 100,
  UNVERIFIED: 101
};

export const rolesIds = {
  ADMIN: 2,
  SYSTEM_MANAGER: 8,
  COURT_GROUP_MANAGER: 3,
  PLAYER: 1,
  UNVERIFIED: 5
};

export const COURT_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OUT_OF_SERVICE: 'out_of_service',
  CREATED: 'created'
};

export const COURT_STATUSES_FORMATED = {
  [COURT_STATUSES.ACTIVE]: () => I18n.t('desktop.selects.active'),
  [COURT_STATUSES.INACTIVE]: () => I18n.t('desktop.selects.inactive'),
  [COURT_STATUSES.OUT_OF_SERVICE]: () => I18n.t('desktop.selects.out_of_service'),
  [COURT_STATUSES.CREATED]: () => I18n.t('desktop.selects.created')
};

export const courtStatusSelect = [
  {
    label: COURT_STATUSES_FORMATED[COURT_STATUSES.ACTIVE],
    value: COURT_STATUSES.ACTIVE
  },
  {
    label: COURT_STATUSES_FORMATED[COURT_STATUSES.INACTIVE],
    value: COURT_STATUSES.INACTIVE
  },
  {
    label: COURT_STATUSES_FORMATED[COURT_STATUSES.OUT_OF_SERVICE],
    value: COURT_STATUSES.OUT_OF_SERVICE
  }
];

export const COURT_GROUP_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OUT_OF_SERVICE: 'out_of_service',
  CREATED: 'created'
};

export const COURT_GROUP_STATUSES_FORMATED = {
  [COURT_GROUP_STATUSES.ACTIVE]: () => I18n.t('desktop.selects.active'),
  [COURT_GROUP_STATUSES.INACTIVE]: () => I18n.t('desktop.selects.inactive'),
  [COURT_GROUP_STATUSES.OUT_OF_SERVICE]: () => I18n.t('desktop.selects.out_of_service'),
  [COURT_GROUP_STATUSES.CREATED]: () => I18n.t('desktop.selects.created')
};

export const courtGroupsStatusSelect = [
  {
    label: COURT_GROUP_STATUSES_FORMATED[COURT_GROUP_STATUSES.ACTIVE],
    value: COURT_GROUP_STATUSES.ACTIVE
  },
  {
    label: COURT_GROUP_STATUSES_FORMATED[COURT_GROUP_STATUSES.INACTIVE],
    value: COURT_GROUP_STATUSES.INACTIVE
  },
  {
    label: COURT_GROUP_STATUSES_FORMATED[COURT_GROUP_STATUSES.OUT_OF_SERVICE],
    value: COURT_GROUP_STATUSES.OUT_OF_SERVICE
  },
];

export const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
};

export const days = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday',
};

export const RESERVATION_STATUSES = {
  CREATED: 'created',
  CANCELLED: 'cancelled',
  PAID: 'paid',
  STARTED: 'started',
  ENDED: 'ended',
};

export const RESERVATION_STATUSES_FORMATED = {
  [RESERVATION_STATUSES.CREATED]: () => I18n.t('desktop.selects.created'),
  [RESERVATION_STATUSES.CANCELLED]: () => I18n.t('desktop.selects.cancelled'),
  [RESERVATION_STATUSES.PAID]: () => I18n.t('desktop.selects.paid'),
  [RESERVATION_STATUSES.STARTED]: () => I18n.t('desktop.selects.started'),
  [RESERVATION_STATUSES.ENDED]: () => I18n.t('desktop.selects.ended'),
};

export const ASSET_TYPES = {
  AIR_CONDITION: 'airCondition',
  ROUTER: 'router',
  CABINET: 'cabinet',
  MULTIMEDIA_RACK: 'multimediaRack',
  TABLET: 'tablet',
  CAMERA: 'camera',
  SPEAKER: 'speaker',
  MAIN_CONTROLLER: 'mainController',
  CONTROLLER: 'controller',
  MAGNETIC_LOCK: 'magneticDoor',
};

export const ASSET_TYPES_FORMATED = {
  [ASSET_TYPES.AIR_CONDITION]: () => I18n.t('desktop.selects.air_condition'),
  [ASSET_TYPES.ROUTER]: () => I18n.t('desktop.selects.router'),
  [ASSET_TYPES.CABINET]: () => I18n.t('desktop.selects.cabinet'),
  [ASSET_TYPES.MULTIMEDIA_RACK]: () => I18n.t('desktop.selects.multimedia_rack'),
  [ASSET_TYPES.TABLET]: () => I18n.t('desktop.selects.tablet'),
  [ASSET_TYPES.CAMERA]: () => I18n.t('desktop.selects.camera'),
  [ASSET_TYPES.SPEAKER]: () => I18n.t('desktop.selects.speaker'),
  [ASSET_TYPES.MAIN_CONTROLLER]: () => I18n.t('desktop.selects.main_controller'),
  [ASSET_TYPES.CONTROLLER]: () => I18n.t('desktop.selects.controller'),
  [ASSET_TYPES.MAGNETIC_LOCK]: () => I18n.t('desktop.selects.magnetic_lock'),
};

export const assetTypeSelect = [
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.AIR_CONDITION], value: ASSET_TYPES.AIR_CONDITION },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.ROUTER], value: ASSET_TYPES.ROUTER },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.CABINET], value: ASSET_TYPES.CABINET },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.MULTIMEDIA_RACK], value: ASSET_TYPES.MULTIMEDIA_RACK },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.TABLET], value: ASSET_TYPES.TABLET },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.CAMERA], value: ASSET_TYPES.CAMERA },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.SPEAKER], value: ASSET_TYPES.SPEAKER },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.MAIN_CONTROLLER], value: ASSET_TYPES.MAIN_CONTROLLER },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.CONTROLLER], value: ASSET_TYPES.CONTROLLER },
  { label: ASSET_TYPES_FORMATED[ASSET_TYPES.MAGNETIC_LOCK], value: ASSET_TYPES.MAGNETIC_LOCK },
];

export const GENDERS = {
  MALE: 'm',
  FEMALE: 'f',
  OTHER: 'o'
};

export const GENDERS_FORMAT = {
  [GENDERS.MALE]: () => I18n.t('desktop.selects.male'),
  [GENDERS.FEMALE]: () => I18n.t('desktop.selects.female'),
  [GENDERS.OTHER]: () => I18n.t('desktop.selects.other')
};

export const genderSelect = [
  {
    label: GENDERS_FORMAT[GENDERS.MALE], value: GENDERS.MALE
  },
  {
    label: GENDERS_FORMAT[GENDERS.FEMALE], value: GENDERS.FEMALE
  },
  {
    label: GENDERS_FORMAT[GENDERS.OTHER], value: GENDERS.OTHER
  },
];
