import moment from 'moment';

export const humanizeCameCase = (str = '') => str
// insert a space before all caps
  .replace(/([A-Z])/g, ' $1')
// uppercase the first character
  .replace(/^./, str => str.toUpperCase());

export const cleanEmptyFromObject = object => Object.entries(object || {})
  .reduce((acc, [key, val]) => {
    if (val !== null && val !== undefined && val !== '') {
      acc[key] = val;
    }
    return acc;
  }, {});

export const formatPhoneNumber = phone => phone.split(' ').splice(1, phone.split(' ').length).join``.replace(/[^0-9]/g, '');

export const dateFormat = (date, format = 'DD-MM-YYYY') => moment(date).format(format);