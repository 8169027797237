import React from 'react';
import PropTypes from 'prop-types';

import { Input, InputAdornment } from '@material-ui/core';

const InputRenderer = ({ value: propsValue, ...props }) => {
  let value = propsValue;
  if (typeof propsValue === 'function') {
    value = propsValue();
  }
  return (
    <Input
      endAdornment={(
        <InputAdornment position="end">
          <i className="material-icons">
            arrow_drop_down
          </i>
        </InputAdornment>
      )}
      value={value}
      {...props}
    />
  );
};

InputRenderer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

InputRenderer.defaultProps = {
  value: ''
};

export default InputRenderer;
