import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, FormControl, InputLabel, InputAdornment, IconButton
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './styles.scss';
import AutoCompleteSelect from './auto-complete-select';


class FormField extends Component {
  endAdornment = () => {
    const {
      name,
      values,
      setFieldValue,
    } = this.props;
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setFieldValue(`showPassword-${name}`, !values[`showPassword-${name}`]);
          }
          }
        >
          {values[`showPassword-${name}`] ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  calculateVisibilityOfPassword = (visibility) => {
    if (visibility) {
      return 'text';
    }
    return 'password';
  }

  renderFieldByType = () => {
    const {
      name,
      label,
      values,
      errors,
      touched,
      handleChange,
      options,
      setFieldValue,
      type,
      ...rest
    } = this.props;

    if (type === 'select') {
      return (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
          <FormControl fullWidth>
            {label && (
              <InputLabel htmlFor={`${name}-select`}>{label}</InputLabel>
            )}
            <AutoCompleteSelect
              name={name}
              label={label}
              onChange={handleChange}
              value={values[name] || ''}
              options={options}
              fullWidth
              error={!!(errors[name] && touched[name])}
              {...rest}
            />
            {errors[name] && touched[name] && (
              <div className="error-message">
                {errors[name]}
              </div>
            )}
          </FormControl>
        </div>
      );
    }
    return (
      <div style={{ position: 'relative', marginBottom: '10px' }}>
        <TextField
          id={name}
          name={name}
          label={label}
          value={values[name] || ''}
          onChange={handleChange}
          error={!!(errors[name] && touched[name])}
          InputProps={{
            endAdornment: type === 'password' ? this.endAdornment() : null
          }}
          type={type === 'password' ? this.calculateVisibilityOfPassword(values[`showPassword-${name}`]) : type}
          {...rest}
        />
        {errors[name] && touched[name] && (
          <div className="error-message">
            {errors[name]}
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      this.renderFieldByType()
    );
  }
}

FormField.defaultProps = {
  type: 'text',
  label: '',
  children: null,
  disabled: false,
  errors: {},
  touched: {},
  options: [],
  setFieldValue: () => { }
};

FormField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  touched: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  options: PropTypes.arrayOf(PropTypes.object),
};


export default FormField;
