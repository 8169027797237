import axios from 'axios';
import api from './api';

/**
 * @method getEthereumMetadata
 */
class ApiClient {
  constructor(apiBase) {
    this.apiBase = apiBase;
    this.token = null;
  }

  /**
   * @param {string|null} token
   */
  setJwtToken(token) {
    this.token = token;
  }

  /**
   * @param {string} apiBase
   */
  setApiBase(apiBase) {
    this.apiBase = apiBase;
  }

  /**
   * Injects API methods from config
   * @param {*} metadata
   */
  injectApiMethods(metadata) {
    // eslint-disable-next-line no-unused-vars
    for (const name of Object.keys(metadata)) {
      this[name] = async (...args) => {
        const {
          method,
          url,
          data,
          transform,
          custom
        } = await metadata[name](...args);

        if (custom) {
          return await custom(...args);
        }

        let result = await this.request(method, url, data);

        if (transform && typeof transform === 'function') {
          result = await transform(result);
        }

        return result;
      };
    }

    return this;
  }

  /**
   * Make a request to API
   * @param {string} method
   * @param {string} url
   * @param {object} options
   * @param {*} data
   */
  async request(method, url, data, options = {}) {
    const headers = this.headers();
    const currLang = localStorage.getItem('i18n-locale') || 'en';

    // @ref https://www.npmjs.com/package/axios
    const params = {
      withCredentials: true,
      method,
      headers: { ...headers, currLang, 't-utc-offset': this.getTimezoneOffset() },
      url,
      baseURL: this.apiBase,
      [this.dataSendType(method)]: this.serialize(data, method),
      ...options,
    };

    const response = await axios.request(params);

    return response.data;
  }

  serialize(data, method) {
    return data && ['POST', 'PUT'].includes(method.toUpperCase()) ? JSON.stringify(data) : data;
  }

  /**
   * @param {string} method
   * @returns {string}
   */
  dataSendType(method) {
    return method === 'GET' ? 'params' : 'data';
  }

  /**
   * Build request headers
   */
  headers() {
    const headers = ApiClient.STATIC_HEADERS;

    if (this.token) {
      headers.Authorization = `JWT ${this.token}`;
    }

    return headers;
  }

  addErrorInterceptor(interceptorFunc) {
    axios.interceptors.response.use(r => r, interceptorFunc);
  }

  /**
   * @param {string} relativeUrl
   * @param {boolean} withToken
   * @returns {string}
   */
  generateAbsoluteUrl(relativeUrl, withToken = true) {
    const endpoint = `/${relativeUrl}`.replace(/\/+/g, '/');
    let url = `${this.apiBase}${endpoint}`;

    if (withToken && this.token) {
      url += `?${ApiClient.JWT_QUERY_TOKEN}=${this.token}`;
    }

    return url;
  }

  generateFileUrl(fileId) {
    return this.generateAbsoluteUrl(`/storage/file/${fileId}`);
  }

  getTimezoneOffset() {
    const offset = new Date().getTimezoneOffset();
    return offset && offset * -1;
  }

  /**
   * @param {*} args
   */
  static create(...args) {
    const client = new this(...args);

    return client.injectApiMethods(api);
  }

  /**
   * Static headers
   */
  static get STATIC_HEADERS() {
    return {
      'Content-Type': 'application/json',
    };
  }

  /**
   * @returns {string}
   */
  static get JWT_QUERY_TOKEN() {
    return 'jwtToken';
  }

  /**
   * @return {String}
   */
  static get LS_KEY() {
    return '__token_dashboard__';
  }

  /**
   * @return {String}
   */
  static get ACTION_KEY() {
    return '__api_req__';
  }
}

export default ApiClient;
