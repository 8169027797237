import React from 'react';
import { connect } from 'react-redux';
import {
  MuiThemeProvider, createMuiTheme, createGenerateClassName, jssPreset
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import JssProvider from 'react-jss/lib/JssProvider';

import Routes from './routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Custom Material-UI class name generator.
const generateClassName = createGenerateClassName();


const App = () => {
  const [body] = document.getElementsByTagName('body');
  const localLang = localStorage.getItem('i18n-locale');
  if (localLang === 'he') {
    body.setAttribute('dir', 'rtl');
  } else {
    body.setAttribute('dir', 'ltr');
  }
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#27AE60' },
      secondary: { main: '#11cb5f' },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
      },
    },
    typography: { useNextVariants: true },
    direction: localLang === 'he' ? 'rtl' : 'ltr'
  });

  return (
    <MuiThemeProvider theme={theme}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Routes />
      </JssProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = ({ translations }) => ({
  currLang: translations.currLang
});

export default connect(mapStateToProps, {})(App);
