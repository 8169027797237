import mergeObjects from 'merge-objects';

import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  audio: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_MESSAGES_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_MESSAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_MESSAGES_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_MESSAGE_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_MESSAGE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: { ...mergeObjects(state.data, payload) },
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_MESSAGE_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_AUDIO_MESSAGES_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_AUDIO_MESSAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    audio: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_AUDIO_MESSAGES_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_AUDIO_MESSAGE_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_AUDIO_MESSAGE_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_AUDIO_MESSAGE_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
