import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Fade,
  MenuList,
  Button,
  Popper,
  Paper,
  ClickAwayListener
} from '@material-ui/core';

import './styles.scss';

class DropDownFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOptionsOpen: false,
      optionsMenu: null
    };
  }

  handleOptionsMenu = (e) => {
    const { isOptionsOpen } = this.state;
    this.setState({
      isOptionsOpen: !isOptionsOpen,
      optionsMenu: e.currentTarget
    });
  }

  closeMenu = (e) => {
    const { optionsMenu } = this.state;
    if (optionsMenu.contains(e.target)) {
      return;
    }
    this.setState({ isOptionsOpen: false });
  }

  render() {
    const {
      options, name, label, handleChange
    } = this.props;
    const { optionsMenu, isOptionsOpen } = this.state;

    return (
      <div style={{ position: 'relative', display: 'flex' }}>
        <Button
          className="toggle-columns-btn"
          aria-label="Options"
          onClick={this.handleOptionsMenu}
          aria-owns={isOptionsOpen ? `simple-menu-${name}` : undefined}
          aria-haspopup="true"
        >
          {label}
          <i className="material-icons">{isOptionsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
        </Button>
        <Popper
          className="drop-down-menu"
          id="popper"
          anchorEl={optionsMenu}
          open={isOptionsOpen}
          transition
          disablePortal
          position="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Fade
              {...TransitionProps}
              id={`simple-menu-${name}`}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center top'
              }}
            >
              <ClickAwayListener onClickAway={this.closeMenu}>
                <Paper className="papper-dropdown">
                  <MenuList>
                    {options.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        onClick={() => handleChange({ value, filter: name })}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

DropDownFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleColumns: PropTypes.func,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};


DropDownFilter.defaultProps = {
  handleColumns: () => null
};

export default DropDownFilter;