import React from 'react';
import { DialogProvider, DialogConsumer } from '../utility/context/dialogContext';

const withConfirmDialog = Component => props => (
  <DialogProvider>
    <DialogConsumer>
      {({ showDialog, hideDialog }) => (
        <Component
          {...props}
          showDialog={showDialog}
          hideDialog={hideDialog}
        />
      )}
    </DialogConsumer>
  </DialogProvider>

);

export default withConfirmDialog;
