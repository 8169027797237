import * as c from '../constants/actions';

export const uploadFileRequest = () => ({
  type: c.UPLOAD_FILE_REQUEST,
});

export const uploadFileSuccess = () => ({
  type: c.UPLOAD_FILE_SUCCESS,
});

export const uploadFileError = () => ({
  type: c.UPLOAD_FILE_ERROR,
});