import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_RESERVATIONS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_RESERVATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_RESERVATIONS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_RESERVATION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_RESERVATION_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.CREATE_RESERVATION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_RESERVATION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_RESERVATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map(el => (el.id === payload.id
        ? { ...el, ...payload }
        : el
      ))
    },
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_RESERVATION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.DELETE_RESERVATION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.DELETE_RESERVATION_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.DELETE_RESERVATION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.START_OR_END_GAME_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.START_OR_END_GAME_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map(el => (el.id === payload.id
        ? { ...el, ...payload }
        : el
      ))
    },
    error: null,
    isLoading: false,
  }),
  [c.START_OR_END_GAME_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
