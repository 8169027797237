import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import MainLayout from '../layouts/main-layout';

const MainLayoutRoute = ({
  render, isAuthenticated, accessRoles, level, ...rest
}) => (
  <Route
    {...rest}
    render={(matchProps) => {
      if (accessRoles.includes(level)) {
        return (
          <MainLayout {...matchProps}>{render({ ...matchProps, isAuthenticated })}</MainLayout>
        );
      }

      return <Redirect to="auth" />;
    }}
  />
);

MainLayoutRoute.propTypes = {
  render: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  accessRoles: PropTypes.arrayOf(PropTypes.number),
  level: PropTypes.number.isRequired
};

MainLayoutRoute.defaultProps = {
  accessRoles: []
};

const mapStateToProps = ({ auth: { isAuthenticated, data: { role } }, files }) => ({
  level: role ? role.level : 101,
  isAuthenticated,
  isLoading: files.isLoading
});


export default connect(mapStateToProps, {})(MainLayoutRoute);
