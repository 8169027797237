export default {
  he: {
    desktop: {
      users: {
        date_of_creation: 'Date of creation',
        add_new_user: '+ Add new user ',
        user_id: 'זיהוי',
        full_name_label: 'Full name',
        email_label: 'Email',
        status_label: 'Status',
        role_label: 'Role',
        basic_information_label: 'Basic information',
        first_name_label: 'First name',
        last_name_label: 'Last name',
        country_label: 'Country',
        city_label: 'City',
        state_label: 'State',
        phone_number_label: 'Phone number',
        address_label: 'Address',
        actions_label: 'Actions',
        user_privileges_label: 'User privileges',
        user_status_label: 'User status',
        element_header_label: 'Users',
        search_label: 'Search...',
        status_comment_label: 'Status comment',
        deporting_reason_label: 'Deporting reason',
        operator_id_label: 'Operator Id',
        gender_label: 'Gender',
        birthday_label: 'Birthday',
        court_group_id_label: 'Court group Id',
        free_plan_label: 'Free plan',
        mid_name_label: 'Mid name'
      },
      operators: {
        date_of_creation: 'Date of creation',
        add_new_operator: '+ Add new operator',
        operator_id: 'Operator Id',
        full_name_label: 'Full name',
        email_label: 'Email',
        status_label: 'Status',
        company_name_label: 'Company name',
        basic_information_label: 'Basic information',
        contact_first_name_label: 'Contact Person first name',
        contact_last_name_label: 'Contact Person last name',
        country_label: 'Country',
        city_label: 'City',
        state_label: 'State',
        phone_number_label: 'Phone number',
        address_label: 'Address',
        element_header_label: 'Operators',
        manager_id_label: 'Manager Id',
        add_new_manager_btn: 'Add new manager'
      },
      subscription_plans: {
        date_of_creation: 'Date of creation',
        add_new_plan: '+ Add new plan',
        id: 'Id',
        name_label: 'Name',
        price_label: 'Price',
        status_label: 'Status',
        type_label: 'Type',
        currency_label: 'Currency',
        element_header_label: 'Subscription plans',
        court_groups_label: 'Court groups',
        recurrent_type_label: 'Recurrent type',
        recurrent_times_label: 'Recurrent times'
      },
      court_groups: {
        date_of_creation: 'Date of creation',
        add_new_group: '+ Add new court group',
        entity_id: 'Entity Id',
        group_name_label: 'Group name',
        status_label: 'Status',
        latitude_label: 'Latitude',
        longitude_label: 'Longitude',
        country_label: 'Country',
        city_label: 'City',
        state_label: 'State',
        address_label: 'Address',
        element_header_label: 'Court groups',
        operator_id_label: 'Operator Id'
      },
      courts: {
        date_of_creation: 'Date of creation',
        add_new_court: '+ Add new court',
        court_id: 'Court Id',
        name_label: 'Name',
        status_label: 'Status',
        latitude_label: 'Latitude',
        longitude_label: 'Longitude',
        court_group_label: 'Court group',
        element_header_label: 'Courts',
        ac_label: 'Air Condition',
        no_ac_for_court_label: "This court don't have an AC!",
        air_condition_label: 'Air condition',
        fan_label: 'Fan',
        city_label: 'City',
        address_label: 'Address'
      },
      assets: {
        date_of_creation: 'Date of creation',
        add_new_asset: '+ Add new asset',
        asset_id: 'Asset Id',
        name_label: 'Name',
        asset_type_label: 'Asset type',
        status_label: 'Status',
        catalog_number_label: 'Catalog number',
        engraved_number_label: 'Engraved number',
        court_id_label: 'Court Id',
        court_group_id_label: 'Court group Id',
        element_header_label: 'Assets',
        asset_status_history_label: 'Asset status history',
        id: 'Id',
        date_of_reporting: 'Date of reporting',
        fault_description: 'Fault description',
        reporter_name: 'Reporter name',
        moved_by: 'Moved By',
        location: 'Location',
        change_asset_status: 'Change asset status',
        unit_id_label: 'Unit Id'
      },
      activity_logs: {
        date_of_creation: 'Date of creation',
        event_id: 'Event Id',
        category_label: 'Category',
        actor_id_label: 'Actor Id',
        description_label: 'Event description',
        caction_label: 'Action',
        from_label: 'From',
        to_label: 'To',
        event_type_label: 'Event type',
        user_label: 'User',
        court_label: 'Court',
        payment_method_label: 'Payment method',
        apply_btn: 'Apply',
        clear_btn: 'Clear',
        element_header_label: 'Activity logs',
        action_label: 'Action'
      },
      translations: {
        select_language_label: 'Select language to edit',
        platform_label: 'Platform',
        element_header_label: 'Translations',
        save_all_btn: 'שמור הכל',
        add_new_compartment_btn: 'Add new compartment'
      },
      dashboard: {
        something: 'hello',
        element_header_label: 'לוח המחוונים',
        report_type: 'report type',
        court_id: 'Court Id',
        top_players: 'Top players',
        preview_label: 'Preview',
        from_hour_label: 'From hour He',
        to_hour_label: 'To hour',
        user_id_label: 'User Id',
        user_name_label: 'User name',
        total_play_time_label: 'Total play time',
        court_usage_label: 'Court usage',
        summary_total_label: 'Summary - total: ',
        download_label: 'Download',
        table_label: 'Table',
        graphic_label: 'Graphic',
        select_court_label: 'Select court',
        date_label: 'Date',
        total_available_time_label: 'Total available time',
        court_utilization_label: 'Court utilization',
        transaction_id: 'Transaction Id',
        payment_date: 'Payment date',
        payment_type: 'Payment type',
        payment_amount: 'Payment amount',
        payment_label: 'Payment',
        court_revenue: 'Court revenue',
        currency_label: 'Currency',
        revenue_generation_label: 'Revenue generation',
        player_label: 'Player',
        games_label: 'Games',
        revenues_label: 'Revenues',
        courts_label: 'Courts',
        court_id_label: 'Court id',
        court_groups_id_label: 'Club Id',
        date_range_label: 'Date range',
        from_label: 'From',
        to_label: 'To',
        for_label: 'For'
      },
      navbar: {
        my_profile: 'My Profile',
        settings: 'Settings',
        log_out: 'Log out'
      },
      sidebar: {
        dashboard: 'Dashboard',
        users: 'Users',
        operators: 'Operators',
        subscription_plans: 'Subscription Plans',
        court_groups: 'Court Groups',
        courts: 'Courts',
        assets: 'Assets',
        activity_logs: 'Activity Logs',
        translations: 'Translations',
        parameters: 'Parameters',
        assets_logs: 'Assets Logs',
        asset_logs: 'Assets Logs',
        reservations: 'Reservations',
        messages: 'Messages',
        system_parameters: 'Club Parameters'
      },
      login: {
        log_in_label: 'Log in',
        email_label: 'Email',
        password_label: 'Password',
        forgot_password_label: 'Forgot password?',
        log_in_btn: 'Log in'
      },
      forgot_password: {
        forgot_label: 'Forgot password',
        email_label: 'Email',
        send_btn: 'Send'
      },
      reset_password: {
        reset_label: 'Reset password',
        email_label: 'Email',
        password_label: 'Password',
        repeat_password_label: 'Repeat password',
        save_btn: 'Save'
      },
      parameters: {
        element_header_label: ' Parameters',
        time_to_cancel_label: 'Time to cancel court reservation',
        time_to_ending_label: 'Time for ending game reminder',
        automatic_air_condition_label: 'Automatic Air Condition start',
        temporary_court_reservation_label: 'Temporary court reservation',
        minutesAfterGameBuffer: 'Minutes after game buffer',
        max_games_per_day_in_court: 'Max games per day',
        minutes_before_game_buffer: 'Minutes before game buffer',
        min_minutes_between_games: 'Min minutes between games',
        max_hours_before_reserving: 'Max hours before reserving',
        min_hours_before_reserving: 'Min hours before reserving',
        minHoursToBePaid: 'Min hours to be paid',
        min_hours_to_keep_or_cancel_game: 'Min hours to keep or cancel game',
        min_hours_to_confirm_game: 'Min hours to confirm game',
        max_days_before_reservation: 'Max days before reservation',
        club_parameters_label: 'Club parameters',
        parameters_label: 'System parameters',
        minimum_age_label: 'Minimum age',
        court_group_label: 'Court group',
        select_court_group_label: 'Select court group'
      },
      common: {
        search_label: 'Search...',
        cancel_btn: 'Cancel',
        save_btn: 'Save',
        edit_btn: 'ערוך',
        view_columns_label: 'View columns',
        place_photo_label: 'Place photo',
        repalce_label: 'Replace',
        delete_btn: 'למחוק',
        change_status_btn: 'Change status',
        status_history_btn: 'Status history',
        settings_btn: 'Settings',
        done_btn: 'Done',
        system: 'System',
        actions_label: 'Actions',
        disable_btn: 'Disable',
        on_label: 'ON',
        off_label: 'OFF',
        loading_label: 'Loading...'
      },
      errors_messages: {
        filed_is_required: 'שדה חובה',
        element_header_label: 'Errors messages',
        number_positive: 'Number must be positive',
        max_length_100_chars: 'Max length is 100 chars',
        max_length_500_chars: 'Max length is 500 chars',
        invalid_phone_number: 'Invalid phone number',
        integer_number: 'Number must be integer',
        'minimum_-90_deg': 'Minimum is -90',
        maximum_90: 'Maximum is 90',
        'minimum_-180': 'Minimum is -180',
        maximum_180: 'Maximum is 180',
        invalid_email: 'Invalid email',
        password_min_length_6: 'Password must contain at least 6 symbols',
        passwords_dont_match: "Passwords don't match",
        start_at_fixed_hour: 'Must start from at fixed hour',
        not_allowed_past_time: 'Not allowed past time',
        court_group_must_not_have_active_courts: 'Court group must not have active courts HE',
        reservation_code_invalid: 'Reservation code invalid',
        reservation_invitation_already_accepted: 'Reservation invitation already accepted',
        reservation_invitation_already_rejected: 'Reservation invitation already rejected',
        payment_missing_pay_per_game_subscription_plan: 'Payment missing pay per game subscription plan',
        payment_missing_or_invalid_signature: 'Payment missing or invalid signature',
        payment_method_not_found: 'Payment method not found',
        user_not_found: 'User not found',
        user_email_not_unique: 'User email not unique',
        user_phone_number_not_unique: 'User phone number not unique',
        user_no_court_access: 'User no court access',
        reservation_timeframe_too_soon_or_too_late: 'Reservation timeframe too soon or too late',
        user_no_court_group_access: 'User no court group access',
        reservation_timeframe_available: 'Reservation timeframe available',
        reservation_court_not_available: 'Reservation court not available',
        reservation_one_or_more_players_are_busy: 'Reservation one or more players are busy',
        reservation_reached_limit_per_day_court_group: 'Reservation reached limit per day court group',
        reservation_too_close_timeframe: 'Reservation too close timeframe',
        reservation_no_access: 'Reservation no access',
        reservation_not_found: 'Reservation not found',
        reservation_not_paid: 'Reservation not paid',
        reservation_not_started: 'Reservation not started',
        reservation_cannot_be_extended: 'Reservation cannot be extended',
        reservation_not_ongoing: 'Reservation not ongoing',
        reservation_already_started: 'Reservation already started',
        reservation_already_ended: 'Reservation already ended',
        reservation_cannot_be_cancelled: 'Reservation cannot be cancelled',
        reservation_cannot_be_started: 'Reservation cannot be started',
        reservation_barcode_invalid: 'Reservation barcode invalid',
        user_does_not_have_a_profile: 'User does not have a profile',
        user_confirmation_code_invalid: 'User confirmation code invalid',
        profile_already_exists: 'Profile already exists',
        profile_not_found: 'Profile not found',
        operator_not_found: 'Operator not found',
        asset_operation_failed: 'Asset operation failed',
        asset_air_conditioner_not_found: 'Asset air conditioner not found',
        asset_not_found: 'Asset not found',
        asset_status_must_be_not_fixable: 'Asset status must be not fixable',
        asset_light_door_not_found: 'Asset light door not found',
        court_not_found: 'Court not found',
        court_group_not_found: 'Court group not found',
        message_not_found: 'Message not found',
        message_not_allowed: 'Message not allowed',
        message_sender_must_not_be_receiver: 'Message sender must not be receiver',
        subscription_not_found: 'Subscription not found',
        subscription_already_active: 'Subscription already active',
        subscription_plan_not_found: 'Subscription plan not found',
        friendship_not_found: 'Friendship not found',
        statistics_aready_exists: 'Statistics aready exists',
        max_size_file_5_mb: 'Max file size is 5Mb',
        less_or_equal_15_mins: 'After game end and before game starts buffers should sum maximum 15 min',
        must_bell_les_that_before_buffer: 'Must be less or equal to the minutes before game buffer',
        must_be_less_that_before_buffer: 'Must be less or equal to the minutes before game buffer',
        invalit_parmeters_in_mobile_translation: 'Invalid parameters, allowed is ',
        no_one_label: 'no one'
      },
      assets_logs: { element_header_label: 'Assets logs' },
      messages: {
        element_header_label: 'Messages',
        save_all_btn: 'Save all',
        help_tip: '* Variables put in {{  }} like {{name}} !'
      },
      reservations: {
        element_header_label: 'Reservations',
        id_label: 'Id',
        from_hour_label: 'From',
        court_id_label: 'Court Id',
        to_hour_label: 'To',
        reserver_id_label: 'Reserver Id',
        status_label: 'Status',
        add_new_reservation: '+ Add new reservation',
        first_player_label: 'First player',
        second_player_label: 'Second player',
        court_group_id_label: 'Court group Id',
        start_game_label: 'Start game',
        end_game_label: 'End game',
        minutes_label: 'Minutes',
        date_label: 'Date'
      },
      success_messages: {
        element_header_label: 'Not set',
        user_added_successfully: 'User was added successfully',
        user_edited_successfully: 'User was edited successfully',
        user_deleted_successfully: 'User was deleted successfully',
        operator_added_successfully: 'Operator was added successfully',
        operator_edited_successfully: 'Operator was edited successfully',
        operator_deleted_successfully: 'Operator was deleted successfully',
        court_group_added_successfully: 'Court group was added successfully',
        court_group_edited_successfully: 'Court group was edited successfully',
        court_group_deleted_successfully: 'Court group was deleted successfully',
        court_added_successfully: 'Court was added successfully',
        court_edited_successfully: 'Court was edited successfully',
        court_deleted_successfully: 'Court was deleted successfully',
        asset_added_successfully: 'Asset was added successfully',
        asset_edited_successfully: 'Asset was edited successfully',
        asset_deleted_successfully: 'Asset was deleted successfully',
        reservation_added_successfully: 'Reservation was added successfully',
        reservation_edited_successfully: 'Reservation was edited successfully',
        reservation_deleted_successfully: 'Reservation was deleted successfully',
        parameters_edited_successfully: 'Parameters was edited successfully',
        messages_edited_successfully: 'Messages was edited successfully',
        translation_edited_successfully: 'Translation was edited successfully',
        sub_plan_added_successfully: 'Subscription plan was added successfully',
        sub_plan_edited_successfully: 'Subscription plan was edited successfully',
        sub_plan_deleted_successfully: 'Subscription plan was deleted successfully'
      },
      system_parameters: {
        automatic_air_condition_label: 'Automatic Air Condition start',
        element_header_label: 'Club parameters',
        max_days_before_reservation: 'Max days before reservation',
        max_games_per_day_in_court: 'Max games per day',
        max_hours_before_reserving: 'Max hours before reserving',
        minHoursToBePaid: 'Min hours to be paid',
        minutesAfterGameBuffer: 'Minutes after game buffer',
        minutes_before_game_buffer: 'Minutes before game buffer',
        min_hours_before_reserving: 'Min hours before reserving',
        min_hours_to_confirm_game: 'Min hours to confirm game',
        min_hours_to_keep_or_cancel_game: 'Min hours to keep or cancel game',
        min_minutes_between_games: 'Min minutes between games',
        temporary_court_reservation_label: 'Temporary court reservation',
        time_to_cancel_label: 'Time to cancel court reservation',
        time_to_ending_label: 'Time for ending game reminder'
      }
    },
  },
  en: {
    desktop: {
      activity_logs: {
        action_label: 'Action',
        actor_id_label: 'Actor Id',
        apply_btn: 'Apply',
        caction_label: 'Action',
        category_label: 'Category',
        clear_btn: 'Clear',
        court_label: 'Court',
        date_of_creation: 'Date of creation',
        description_label: 'Event description',
        element_header_label: 'Activity logs',
        event_id: 'Event Id',
        event_type_label: 'Event type',
        from_label: 'From',
        payment_method_label: 'Payment method',
        to_label: 'To',
        user_label: 'User'
      },
      assets: {
        add_new_asset: '+ Add new asset',
        asset_id: 'Asset Id',
        asset_status_history_label: 'Asset status history',
        asset_type_label: 'Asset type',
        catalog_number_label: 'Catalog number',
        change_asset_status: 'Change asset status',
        court_group_id_label: 'Court group Id',
        court_id_label: 'Court Id',
        date_of_creation: 'Date of creation',
        date_of_reporting: 'Date of reporting',
        element_header_label: 'Assets',
        engraved_number_label: 'Engraved number',
        fault_description: 'Fault description',
        id: 'Id',
        location: 'Location',
        moved_by: 'Moved By',
        name_label: 'Name',
        reporter_name: 'Reporter name',
        status_label: 'Status',
        unit_id_label: 'Unit Id'
      },
      assets_logs: { element_header_label: 'Assets logs' },
      common: {
        cancel_btn: 'Cancel',
        change_status_btn: 'Change status',
        delete_btn: 'Delete',
        done_btn: 'Done',
        edit_btn: 'Edit',
        place_photo_label: 'Place photo',
        repalce_label: 'Replace',
        save_btn: 'Save',
        search_label: 'Search...',
        settings_btn: 'Settings',
        status_history_btn: 'Status history',
        view_columns_label: 'View columns',
        system: 'System',
        actions_label: 'Actions',
        disable_btn: 'Disable',
        on_label: 'ON',
        off_label: 'OFF',
        loading_label: 'Loading...'
      },
      courts: {
        add_new_court: '+ Add new court',
        court_group_label: 'Court group',
        court_id: 'Court Id',
        date_of_creation: 'Date of creation',
        element_header_label: 'Courts',
        latitude_label: 'Latitude',
        longitude_label: 'Longitude',
        name_label: 'Name',
        status_label: 'Status',
        ac_label: 'AC',
        no_ac_for_court_label: "This court doesn't have an AC!",
        air_condition_label: 'Air condition',
        fan_label: 'Fan',
        city_label: 'City',
        address_label: 'Address'
      },
      court_groups: {
        address_label: 'Address',
        add_new_group: '+ Add new court group',
        city_label: 'City',
        country_label: 'Country',
        date_of_creation: 'Date of creation',
        element_header_label: 'Court groups',
        entity_id: 'Entity Id',
        group_name_label: 'Group name',
        latitude_label: 'Latitude',
        longitude_label: 'Longitude',
        operator_id_label: 'Operator Id',
        state_label: 'State',
        status_label: 'Status'
      },
      dashboard: {
        court_id: 'Court Id',
        element_header_label: 'Dashboard',
        preview_label: 'Preview',
        report_type: 'Report type',
        something: 'hello',
        top_players: 'Top players',
        from_hour_label: 'From hour',
        to_hour_label: 'To hour',
        user_id_label: 'User Id',
        user_name_label: 'User name',
        total_play_time_label: 'Total play time',
        court_usage_label: 'Court usage',
        summary_total_label: 'Summary - total: ',
        download_label: 'Download',
        table_label: 'Table',
        graphic_label: 'Graphic',
        select_court_label: 'Select court',
        date_label: 'Date',
        total_available_time_label: 'Total available time',
        court_utilization_label: 'Court utilization',
        transaction_id: 'Transaction Id',
        payment_date: 'Payment date',
        payment_type: 'Payment type',
        payment_amount: 'Payment amount',
        payment_label: 'Payment',
        court_revenue: 'Court revenue',
        currency_label: 'Currency',
        revenue_generation_label: 'Revenue generation',
        player_label: 'Player',
        games_label: 'Games',
        revenues_label: 'Revenues',
        courts_label: 'Courts',
        court_id_label: 'Court Id',
        court_groups_id_label: 'Club Id',
        date_range_label: 'Date range',
        from_label: 'From',
        to_label: 'To',
        for_label: 'For'
      },
      errors_messages: {
        element_header_label: 'Not set',
        filed_is_required: 'This field is required',
        integer_number: 'Number must be integer',
        invalid_email: 'Invalid email',
        invalid_phone_number: 'Invalid phone number',
        maximum_180: 'Maximum is 180',
        maximum_90: 'Maximum is 90',
        max_length_100_chars: 'Max length is 100 chars',
        max_length_500_chars: 'Max length is 500 chars',
        'minimum_-180': 'Minimum is -180',
        'minimum_-90_deg': 'Minimum is -90',
        not_allowed_past_time: 'Not allowed past time',
        number_positive: 'Number must be positive',
        passwords_dont_match: "Passwords don't match",
        password_min_length_6: 'Password must contain at least 6 symbols',
        start_at_fixed_hour: 'Must start from at fixed hour',
        court_group_must_not_have_active_courts: 'Court group must not have active courts',
        reservation_code_invalid: 'Reservation code invalid',
        reservation_invitation_already_accepted: 'Reservation invitation already accepted',
        reservation_invitation_already_rejected: 'Reservation invitation already rejected',
        payment_missing_pay_per_game_subscription_plan: 'Payment missing pay per game subscription plan',
        payment_missing_or_invalid_signature: 'Payment missing or invalid signature',
        payment_method_not_found: 'Payment method not found',
        user_not_found: 'User not found',
        user_email_not_unique: 'User email not unique',
        user_phone_number_not_unique: 'User phone number not unique',
        user_no_court_access: 'User no court access',
        reservation_timeframe_too_soon_or_too_late: 'Reservation timeframe too soon or too late',
        user_no_court_group_access: 'User no court group access',
        reservation_timeframe_available: 'Reservation timeframe available',
        reservation_court_not_available: 'Reservation court not available',
        reservation_one_or_more_players_are_busy: 'Reservation one or more players are busy',
        reservation_reached_limit_per_day_court_group: 'Reservation reached limit per day court group',
        reservation_too_close_timeframe: 'Reservation too close timeframe',
        reservation_no_access: 'Reservation no access',
        reservation_not_found: 'Reservation not found',
        reservation_not_paid: 'Reservation not paid',
        reservation_not_started: 'Reservation not started',
        reservation_cannot_be_extended: 'Reservation cannot be extended',
        reservation_not_ongoing: 'Reservation not ongoing',
        reservation_already_started: 'Reservation already started',
        reservation_already_ended: 'Reservation already ended',
        reservation_cannot_be_cancelled: 'Reservation cannot be cancelled',
        reservation_cannot_be_started: 'Reservation cannot be started',
        reservation_barcode_invalid: 'Reservation barcode invalid',
        user_does_not_have_a_profile: 'User does not have a profile',
        user_confirmation_code_invalid: 'User confirmation code invalid',
        profile_already_exists: 'Profile already exists',
        profile_not_found: 'Profile not found',
        operator_not_found: 'Operator not found',
        asset_operation_failed: 'Asset operation failed',
        asset_air_conditioner_not_found: 'Asset air conditioner not found',
        asset_not_found: 'Asset not found',
        asset_status_must_be_not_fixable: 'Asset status must be not fixable',
        asset_light_door_not_found: 'Asset light door not found',
        court_not_found: 'Court not found',
        court_group_not_found: 'Court group not found',
        message_not_found: 'Message not found',
        message_not_allowed: 'Message not allowed',
        message_sender_must_not_be_receiver: 'Message sender must not be receiver',
        subscription_not_found: 'Subscription not found',
        subscription_already_active: 'Subscription already active',
        subscription_plan_not_found: 'Subscription plan not found',
        friendship_not_found: 'Friendship not found',
        statistics_aready_exists: 'Statistics aready exists',
        max_size_file_5_mb: 'Max file size is 5Mb',
        less_or_equal_15_mins: 'After game end and before game starts buffers should sum maximum 15 min',
        must_bell_les_that_before_buffer: 'Must be less or equal to the minutes before game buffer',
        must_be_less_that_before_buffer: 'Must be less or equal to the minutes before game buffer',
        invalit_parmeters_in_mobile_translation: 'Invalid parameters, allowed is ',
        no_one_label: 'no one'
      },
      forgot_password: {
        email_label: 'Email',
        forgot_label: 'Forgot password',
        send_btn: 'Send'
      },
      login: {
        email_label: 'Email',
        forgot_password_label: 'Forgot password?',
        log_in_btn: 'Log in',
        log_in_label: 'Log in',
        password_label: 'Password'
      },
      messages: {
        element_header_label: 'Messages',
        save_all_btn: 'Save all',
        help_tip: '* Variables put in {{  }} like {{name}} !'
      },
      navbar: {
        log_out: 'Log out',
        my_profile: 'My Profile',
        settings: 'Settings'
      },
      operators: {
        address_label: 'Address',
        add_new_manager_btn: 'Add new manager',
        add_new_operator: '+ Add new operator',
        basic_information_label: 'Basic information',
        city_label: 'City',
        company_name_label: 'Company name',
        contact_first_name_label: 'Contact Person first name',
        contact_last_name_label: 'Contact Person last name',
        country_label: 'Country',
        date_of_creation: 'Date of creation',
        element_header_label: 'Operators',
        email_label: 'Email',
        full_name_label: 'Company name',
        manager_id_label: 'Manager Id',
        operator_id: 'Id',
        phone_number_label: 'Phone number',
        state_label: 'State',
        status_label: 'Status'
      },
      reservations: {
        add_new_reservation: '+ Add new reservation',
        court_group_id_label: 'Court group Id',
        court_id_label: 'Court Id',
        date_label: 'Date',
        element_header_label: 'Reservations',
        end_game_label: 'End game',
        first_player_label: 'First player',
        from_hour_label: 'From',
        id_label: 'Id',
        minutes_label: 'Minutes',
        reserver_id_label: 'Reserver Id',
        second_player_label: 'Second player',
        start_game_label: 'Start game',
        status_label: 'Status',
        to_hour_label: 'To'
      },
      reset_password: {
        email_label: 'Email',
        password_label: 'Password',
        repeat_password_label: 'Repeat password',
        reset_label: 'Reset password',
        save_btn: 'Save'
      },
      sidebar: {
        activity_logs: 'Activity Logs',
        assets: 'Assets',
        assets_logs: 'Assets Logs',
        asset_logs: 'Assets Logs',
        courts: 'Courts',
        court_groups: 'Court Groups',
        dashboard: 'Dashboard',
        messages: 'Messages',
        operators: 'Operators',
        reservations: 'Reservations',
        subscription_plans: 'Subscription Plans',
        system_parameters: 'Club Parameters',
        translations: 'Translations',
        users: 'Users',
        parameters: 'Parameters'
      },
      subscription_plans: {
        add_new_plan: '+ Add new plan',
        court_groups_label: 'Court groups',
        currency_label: 'Currency',
        date_of_creation: 'Date of creation',
        element_header_label: 'Subscription plans',
        id: 'Id',
        name_label: 'Name',
        price_label: 'Price',
        status_label: 'Status',
        type_label: 'Type',
        recurrent_type_label: 'Recurrent type',
        recurrent_times_label: 'Recurrent times'
      },
      system_parameters: {
        automatic_air_condition_label: 'Automatic Air Condition start',
        element_header_label: 'Club parameters',
        max_days_before_reservation: 'Max days before reservation',
        max_games_per_day_in_court: 'Max games per day',
        max_hours_before_reserving: 'Max hours before reserving',
        minHoursToBePaid: 'Min hours to be paid',
        minutesAfterGameBuffer: 'Minutes after game buffer',
        minutes_before_game_buffer: 'Minutes before game buffer',
        min_hours_before_reserving: 'Min hours before reserving',
        min_hours_to_confirm_game: 'Min hours to confirm game',
        min_hours_to_keep_or_cancel_game: 'Min hours to keep or cancel game',
        min_minutes_between_games: 'Min minutes between games',
        temporary_court_reservation_label: 'Temporary court reservation',
        time_to_cancel_label: 'Time to cancel court reservation',
        time_to_ending_label: 'Time for ending game reminder'
      },
      translations: {
        add_new_compartment_btn: 'Add new compartment',
        element_header_label: 'Translations',
        platform_label: 'Platform',
        save_all_btn: 'Save all',
        select_language_label: 'Select language to edit'
      },
      users: {
        actions_label: 'Actions',
        address_label: 'Address',
        add_new_user: '+ Add new User',
        basic_information_label: 'Basic Info',
        birthday_label: 'Birthday',
        city_label: 'City',
        country_label: 'Country',
        court_group_id_label: 'Club name',
        date_of_creation: 'Creation Date',
        deporting_reason_label: 'Deporting reason',
        element_header_label: 'Users',
        email_label: 'Email',
        first_name_label: 'First Name',
        free_plan_label: 'Free plan',
        full_name_label: 'Full Name',
        gender_label: 'Gender',
        last_name_label: 'Last Name',
        mid_name_label: 'Mid name',
        operator_id_label: 'Operator Id',
        phone_number_label: 'Phone Number',
        role_label: 'Role',
        search_label: 'Search...',
        state_label: 'State',
        status_comment_label: 'Status comment',
        status_label: 'Status',
        user_id: 'User ID',
        user_privileges_label: 'User privileges',
        user_status_label: 'User Status'
      },
      success_messages: {
        element_header_label: 'Not set',
        user_added_successfully: 'User was added successfully',
        user_edited_successfully: 'User was edited successfully',
        user_deleted_successfully: 'User was deleted successfully',
        operator_added_successfully: 'Operator was added successfully',
        operator_edited_successfully: 'Operator was edited successfully',
        operator_deleted_successfully: 'Operator was deleted successfully',
        court_group_added_successfully: 'Court group was added successfully',
        court_group_edited_successfully: 'Court group was edited successfully',
        court_group_deleted_successfully: 'Court group was deleted successfully',
        court_added_successfully: 'Court was added successfully',
        court_edited_successfully: 'Court was edited successfully',
        court_deleted_successfully: 'Court was deleted successfully',
        asset_added_successfully: 'Asset was added successfully',
        asset_edited_successfully: 'Asset was edited successfully',
        asset_deleted_successfully: 'Asset was deleted successfully',
        reservation_added_successfully: 'Reservation was added successfully',
        reservation_edited_successfully: 'Reservation was edited successfully',
        reservation_deleted_successfully: 'Reservation was deleted successfully',
        parameters_edited_successfully: 'Parameters was edited successfully',
        messages_edited_successfully: 'Messages was edited successfully',
        translation_edited_successfully: 'Translation was edited successfully',
        sub_plan_added_successfully: 'Subscription plan was added successfully',
        sub_plan_edited_successfully: 'Subscription plan was edited successfully',
        sub_plan_deleted_successfully: 'Subscription plan was deleted successfully'
      },
      parameters: {
        element_header_label: 'Parameters',
        time_to_cancel_label: 'Time to cancel court reservation',
        time_to_ending_label: 'Time for ending game reminder',
        automatic_air_condition_label: 'Automatic Air Condition start',
        temporary_court_reservation_label: 'Temporary court reservation',
        minutesAfterGameBuffer: 'Minutes after game buffer',
        max_games_per_day_in_court: 'Max games per day',
        minutes_before_game_buffer: 'Minutes before game buffer',
        min_minutes_between_games: 'Min minutes between games',
        max_hours_before_reserving: 'Max hours before reserving',
        min_hours_before_reserving: 'Min hours before reserving',
        minHoursToBePaid: 'Min hours to be paid',
        min_hours_to_keep_or_cancel_game: 'Min hours to keep or cancel game',
        min_hours_to_confirm_game: 'Min hours to confirm game',
        max_days_before_reservation: 'Max days before reservation',
        club_parameters_label: 'Club parameters',
        parameters_label: 'System parameters',
        minimum_age_label: 'Minimum age',
        court_group_label: 'Court group',
        select_court_group_label: 'Select court group'
      }
    }
  }
};
