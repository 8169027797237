import React from 'react';
import {
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';

import { logout } from '../../actions/auth';
import { withLoader } from '../../hocs';
import defaultAvatar from '../../media/avatar.svg';
import { LanguageSelect } from '../common';
import './styles.scss';


class Navbar extends React.Component {
  state = {
    isMenuOpen: false,
    menuEl: null,
  }

  handleMenu = (e) => {
    const { isMenuOpen } = this.state;
    this.setState({
      isMenuOpen: !isMenuOpen,
      menuEl: e.currentTarget
    });
  }

  goToSettings = () => {
    const { history } = this.props;

    history.push('/account-settings');
  }

  closeMenu = (e) => {
    const { menuEl } = this.state;
    if (menuEl.contains(e.target)) {
      return;
    }
    this.setState({ isMenuOpen: false });
  }

  render() {
    const { menuEl, isMenuOpen } = this.state;
    const { logout, handleSidebar, data: { profile, email } } = this.props;
    return (
      <div className="navbar">
        <div className="sidebar-icon">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleSidebar}
          >
            <i className="material-icons">menu</i>
          </IconButton>
        </div>
        <div className="d-flex align-items-center">
          <LanguageSelect />
          <div
            className="logo"
            style={{ backgroundImage: `url(${(profile || {}).image || defaultAvatar})` }}
          />
          <div className="main-button" onClick={this.handleMenu}>
            <Translate value="desktop.navbar.my_profile" />
            <i className="material-icons">
              {isMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </i>
            <Popper
              className="drop-down-my-menue"
              anchorEl={menuEl}
              open={isMenuOpen}
              transition
              placement="bottom-end"
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="simple-menu"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.closeMenu}>
                      <MenuList>
                        <MenuItem disabled style={{ cursor: 'not-allowed' }}>{email}</MenuItem>
                        <MenuItem onClick={this.goToSettings}>
                          <Translate value="desktop.navbar.settings" />
                        </MenuItem>
                        <MenuItem onClick={logout}>
                          <Translate value="desktop.navbar.log_out" />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, translations }) => ({
  isLoading: auth.isLoading || translations.isLoading,
  data: auth.data,
});

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  handleSidebar: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps, { logout })(withLoader(Navbar));