import createReducer from './utils/createReducer';
import * as c from '../constants/actions';
import { roles, PROFILE_STATUSES } from '../constants/utils';

const administrationRoles = [roles.ADMIN, roles.COURT_GROUP_MANAGER, roles.SYSTEM_MANAGER];

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  data: {},
  error: null,
};

export default createReducer(initialState, {
  [c.LOGIN_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.LOGIN_SUCCESS]: (state, { payload }) => {
    const { role: { level }, profile } = payload;
    const { ACTIVE } = PROFILE_STATUSES;
    return ({
      ...state,
      data: payload,
      isAuthenticated: (profile || {}).status === ACTIVE && administrationRoles.includes(level),
      isLoading: false,
    });
  },
  [c.LOGIN_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isAuthenticated: false,
    isLoading: false,
  }),
  [c.SOCIAL_LOGIN_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.SOCIAL_LOGIN_SUCCESS]: state => ({
    ...state,
    isAuthenticated: true,
    isLoading: false,
  }),
  [c.SOCIAL_LOGIN_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.LOGOUT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.LOGOUT_SUCCESS]: state => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
  }),
  [c.LOGOUT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.REGISTER_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.REGISTER_SUCCESS]: state => ({
    ...state,
    isAuthenticated: true,
    isLoading: false,
  }),
  [c.REGISTER_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.FORGOT_PASSWORD_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.FORGOT_PASSWORD_SUCCESS]: state => ({
    ...state,
    isLoading: false,
  }),
  [c.FORGOT_PASSWORD_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.RESET_PASSWORD_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.RESET_PASSWORD_SUCCESS]: state => ({
    ...state,
    isLoading: false,
  }),
  [c.RESET_PASSWORD_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_USER_SUCCESS]: (state, { payload }) => {
    if (state.data.id === payload.identityId) {
      return {
        ...state,
        data: {
          ...state.data,
          profile: { ...payload }
        }
      };
    }
    return state;
  },
  [c.UPDATE_USER_PROFILE_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_USER_PROFILE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    isLoading: false,
  }),
  [c.UPDATE_USER_PROFILE_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
