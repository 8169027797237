import { I18n } from 'react-i18nify';

import * as c from '../constants/actions';
import createApiAction from '../api/createApiAction';
import toast from '../utility/toast-notification';

// eslint-disable-next-line
export const getCountriesList = data => createApiAction({
  method: 'getCountriesList',
  requestAction: c.GET_COUNTRIES_LIST_REQUEST,
  successAction: c.GET_COUNTRIES_LIST_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;

    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });

    dispatch({
      type: c.GET_COUNTRIES_LIST_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data.map(({ name, ...rest }) => ({ label: name, value: rest['alpha-2'] })),
});
