import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import './styles.scss';
import defaultLanguage from '../constants/translate';
import { getTranslations } from '../actions/translations';

class TranslationsLayout extends React.Component {
  state = {
    canRender: false,
  }

  componentDidMount() {
    const { getTranslations } = this.props;

    getTranslations();
  }

  componentDidUpdate(prev) {
    const { isLoading } = this.props;

    if (isLoading !== prev.isLoading && prev.isLoading) {
      const { translations } = this.props;
      const language = localStorage.getItem('i18n-locale') || 'en';
      const translation = (
        (translations[language] || {}).desktop
        && (translations[language] || {}).mobile)
        ? translations
        : defaultLanguage;
      I18n.setTranslations(translation);
      I18n.setLocale(language);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ canRender: true });
    }
  }

  render() {
    const { children } = this.props;
    const { canRender } = this.state;
    if (canRender) {
      return (
        <>
          { children }
        </>
      );
    }
    return (
      <div className="loader"><CircularProgress color="primary" size={75} /></div>
    );
  }
}

const mapStateToProps = ({ translations }) => ({
  isLoading: translations.isLoading,
  translations: translations.data
});

TranslationsLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  translations: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTranslations: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { getTranslations })(TranslationsLayout);
