import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_SUBSCRIPTIONS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_SUBSCRIPTIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_SUBSCRIPTIONS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_SUBSCRIPTION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_SUBSCRIPTION_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.CREATE_SUBSCRIPTION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_SUBSCRIPTION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_SUBSCRIPTION_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map(el => (el.id === payload.id
        ? { ...payload, courtGroup: el.courtGroup }
        : el
      ))
    },
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_SUBSCRIPTION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.DELETE_SUBSCRIPTION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.DELETE_SUBSCRIPTION_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.DELETE_SUBSCRIPTION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
