import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  roles: [],
  light: [],
  userFriends: [],
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_USERS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_USERS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_USER_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_USER_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.CREATE_USER_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_USER_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: (state.data.data || []).map(el => (el.id === payload.id
        ? { ...payload, subscriptions: el.subscriptions }
        : el
      ))
    },
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_USER_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.DELETE_USER_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.DELETE_USER_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.DELETE_USER_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_USER_ROLES_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_USER_ROLES_SUCCESS]: (state, { payload }) => ({
    ...state,
    roles: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_USER_ROLES_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_USER_LIGHT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_USER_LIGHT_SUCCESS]: (state, { payload }) => ({
    ...state,
    light: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_USER_LIGHT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_USER_FRIENDS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_USER_FRIENDS_SUCCESS]: (state, { payload }) => ({
    ...state,
    userFriends: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_USER_FRIENDS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.BATCH_EDIT_USERS_STATUS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.BATCH_EDIT_USERS_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map((el) => {
        const { profile, profile: { id } } = el;
        const { ids, status } = payload;
        return ids.includes(id) ? ({ ...el, profile: { ...profile, status } }) : el;
      })
    },
    error: null,
    isLoading: false,
  }),
  [c.BATCH_EDIT_USERS_STATUS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.SET_USER_FREE_PLAN_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.SET_USER_FREE_PLAN_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.SET_USER_FREE_PLAN_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.REMOVE_USER_FREE_PLAN_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.REMOVE_USER_FREE_PLAN_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.REMOVE_USER_FREE_PLAN_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
