import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  light: [],
  courtSettings: {
    ac: {},
    door: {}
  },
  automataState: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_COURTS_LIGHT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURTS_LIGHT_SUCCESS]: (state, { payload }) => ({
    ...state,
    light: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_COURTS_LIGHT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURTS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_COURTS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_COURT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_COURT_SUCCESS]: state => ({
    ...state,
    light: [],
    error: null,
    isLoading: false,
  }),
  [c.CREATE_COURT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_COURT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_COURT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map(el => (el.id === payload.id ? { ...el, ...payload } : el))
    },
    light: [],
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_COURT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.DELETE_COURT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.DELETE_COURT_SUCCESS]: state => ({
    ...state,
    light: [],
    error: null,
    isLoading: false,
  }),
  [c.DELETE_COURT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.SET_COURT_SETTING_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.SET_COURT_SETTING_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.SET_COURT_SETTING_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_SETTING_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_SETTING_SUCCESS]: (state, { payload }) => ({
    ...state,
    courtSettings: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_SETTING_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_AIR_SETTING_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_AIR_SETTING_SUCCESS]: (state, { payload }) => ({
    ...state,
    courtSettings: {
      ...state.courtSettings,
      ac: payload
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_AIR_SETTING_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_AUTOMATA_STATE_REQUEST]: state => ({
    ...state,
    automataState: {},
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_AUTOMATA_STATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    automataState: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_AUTOMATA_STATE_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
