export default {
  login(data) {
    return {
      method: 'POST',
      url: '/identity/login',
      data,
    };
  },
  logout() {
    return {
      method: 'GET',
      url: '/identity/logout',
    };
  },
  register(data) {
    return {
      method: 'POST',
      url: '/identity/register',
      data,
    };
  },
  getUserProfile() {
    return {
      method: 'GET',
      url: '/identity/me'
    };
  },
  forgotPassword(data) {
    return {
      method: 'POST',
      url: '/identity/forgotPassword',
      data
    };
  },
  resetPassword(data) {
    return {
      method: 'POST',
      url: '/identity/resetPassword',
      data
    };
  },
  getNotifications() {
    return {
      method: 'GET',
      url: '/notification/me',
    };
  },
  dismissNotification(id) {
    return {
      method: 'PATCH',
      url: `/notification/dismiss/${id}`,
    };
  },
  readNotifications(ids) {
    return {
      method: 'PATCH',
      url: '/notification/read/bulk',
      data: {
        ids
      }
    };
  },
  getTranslations() {
    return {
      method: 'GET',
      url: 'translation/all',
    };
  },
  saveTranslations(data) {
    return {
      method: 'POST',
      url: 'translation/save/all',
      data,
    };
  },
  updateTranslation(data) {
    return {
      method: 'POST',
      url: 'translation/lang/update',
      data,
    };
  },
  removeTranslation(data) {
    return {
      method: 'POST',
      url: 'translation/lang/remove',
      data,
    };
  },
};
