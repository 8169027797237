import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Grow,
  MenuList,
  Button,
  Popper,
  Paper,
  ClickAwayListener
} from '@material-ui/core';

import './styles.scss';

class ColumnsSettings extends React.Component {
  constructor(props) {
    super(props);
    const { columns, selectedColumns } = props;

    const selectedItems = columns({ selectedColumns: selectedColumns.length ? selectedColumns : 'all' }).map(({ key }) => key);

    this.state = {
      selectedItems,
      isSettingsOpen: false,
      settingsMenu: null
    };
  }

  get getColumnsForView() {
    const { columns } = this.props;
    const tempArr = columns({ selectedColumns: 'all' }).map(({ Header, key }) => {
      if (Header instanceof Function) {
        return { label: Header(), key };
      }
      return { label: Header, key };
    });

    return tempArr;
  }

  handleSettingMenu = (e) => {
    const { isSettingsOpen } = this.state;
    this.setState({
      isSettingsOpen: !isSettingsOpen,
      settingsMenu: e.currentTarget
    });
  }

  closeMenu = (e) => {
    const { settingsMenu } = this.state;
    if (settingsMenu.contains(e.target)) {
      return;
    }
    this.setState({ isSettingsOpen: false });
  }

  handleSelectedColumns = (e) => {
    const { selectedItems } = this.state;
    const { handleColumns } = this.props;

    if (selectedItems.includes(e)) {
      const tempArr = [...selectedItems.filter(el => el !== e)];
      this.setState({ selectedItems: tempArr });
      handleColumns(tempArr);
    } else {
      selectedItems.push(e);
      this.setState({ selectedItems });
      handleColumns(selectedItems);
    }
  }

  render() {
    const { settingsMenu, isSettingsOpen, selectedItems } = this.state;

    return (
      <div style={{ position: 'relative', display: 'flex' }}>
        <Button
          className="toggle-columns-btn"
          aria-label="Settings"
          onClick={this.handleSettingMenu}
          aria-owns={isSettingsOpen ? 'simple-menu-settings' : undefined}
          aria-haspopup="true"
        >
          <Translate value="desktop.common.view_columns_label" />
          <i className="material-icons">{isSettingsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
        </Button>
        <Popper
          className="drop-down-menu"
          anchorEl={settingsMenu}
          open={isSettingsOpen}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="simple-menu-settings"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper style={{ borderRadius: 8, padding: 23 }}>
                <ClickAwayListener onClickAway={this.closeMenu}>
                  <MenuList style={{ padding: 0 }}>
                    {this.getColumnsForView.map(({ label, key }) => (
                      <MenuItem
                        key={key}
                        value={key}
                        onClick={() => this.handleSelectedColumns(key)}
                        style={{ padding: '7px 0px', height: 18 }}
                      >
                        <Checkbox
                          checked={selectedItems.indexOf(key) > -1}
                          color="primary"
                          className="checkbox"
                        />
                        <ListItemText primary={label} className="list-item-text " />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

ColumnsSettings.propTypes = {
  columns: PropTypes.func,
  handleColumns: PropTypes.func,
  selectedColumns: PropTypes.arrayOf(PropTypes.any)
};


ColumnsSettings.defaultProps = {
  selectedColumns: [],
  handleColumns: () => null,
  columns: () => null
};

export default ColumnsSettings;