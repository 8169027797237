import React from 'react';
import { I18n } from 'react-i18nify';
import ReactCountryFlag from 'react-country-flag';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';

import { getCountriesList } from '../../actions/utils';

import { getTranslations, handleCurrentLanguage } from '../../actions/translations';
import { languageSelect } from '../../constants/utils';
import { withLoader } from '../../hocs';

class LanguageSelect extends React.Component {
  state = {
    language: localStorage.getItem('i18n-locale') || 'en'
  };

  componentDidMount() {
    const { getCountriesList } = this.props;
    getCountriesList();
  }

  componentDidUpdate(prevProps) {
    const { currentLanguage, getCountriesList } = this.props;
    if (prevProps.currentLanguage !== currentLanguage) {
      getCountriesList();
    }
  }

  handleLanguage = (e) => {
    const { value } = e.target;
    const { handleCurrentLanguage } = this.props;

    this.setState({ language: value }, () => {
      const [body] = document.getElementsByTagName('body');

      if (value === 'he') {
        body.setAttribute('dir', 'rtl');
      } else {
        body.setAttribute('dir', 'ltr');
      }

      handleCurrentLanguage(value);
      I18n.setLocale(value);
      localStorage.setItem('i18n-locale', value);
    });
  };

  render() {
    const { language } = this.state;

    return (
      <Select value={language} onChange={this.handleLanguage}>
        {languageSelect.map(({ code, value }) => (
          <MenuItem value={value} key={code}>
            <ReactCountryFlag code={code} svg />
          </MenuItem>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = ({ translations }) => ({
  isLoading: translations.isLoading,
  translations: translations.data,
  currentLanguage: translations.currLang
});

LanguageSelect.propTypes = {
  handleCurrentLanguage: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  getCountriesList: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  getTranslations,
  getCountriesList,
  handleCurrentLanguage
})(withLoader(LanguageSelect));
