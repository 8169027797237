import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import {
  Dialog,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

import './styles.scss';

const Pagination = ({
  pages, onPageChange, page, currLang, ...rest
}) => (pages ? (
  <ReactPaginate
    previousLabel={(
      <i className="material-icons">
        { currLang === 'he' ? 'arrow_right' : 'arrow_left' }
      </i>
    )}
    nextLabel={(
      <i className="material-icons">
        { currLang === 'he' ? 'arrow_left' : 'arrow_right' }
      </i>
    )}
    pageClassName="page"
    breakLabel="..."
    breakClassName="break-me"
    pageCount={pages}
    marginPagesDisplayed={2}
    pageRangeDisplayed={1}
    forcePage={page}
    onPageChange={({ selected }) => {
      onPageChange(selected);
    }}
    containerClassName="pagination"
    subContainerClassName="pages pagination"
    activeClassName="active-page"
    nextClassName="next-btn"
    previousClassName="prev-btn"
    {...rest}
  />
) : null);

const mapStateToProps = ({ translations: { currLang } }) => ({
  currLang
});

connect(mapStateToProps, {})(Pagination);

Pagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  currLang: PropTypes.string.isRequired,
  onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
  pages: 0,
  page: 0,
  onPageChange: () => null,
};

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewModalOpen: false,
      entity: null,
      disabled: true
    };
  }


  get columns() {
    const {
      needCheck, columns, data: { data = [] }, checked
    } = this.props;

    const tempColumns = [...columns];

    if (needCheck) {
      tempColumns.unshift({
        Header: () => (
          <Checkbox
            style={{ padding: 0 }}
            onClick={this.checkAll}
            checked={data.length ? data.length === checked.length : false}
          />
        ),
        id: 'checkbox',
        sortable: false,
        accessor: element => (
          <Checkbox
            style={{ padding: 0 }}
            onClick={() => this.handleCheck(element)}
            checked={!!checked.find(el => el.id === element.id)}
          />
        ),
        required: true,
        maxWidth: 40,
        headerStyle: { textAlign: 'left' }
      });
    }

    return tempColumns.map((col) => {
      const tempCol = { ...col };

      if (col.id !== 'action') {
        tempCol.Cell = ({ value, styles }) => {
          if (typeof value !== 'object') {
            return (
              <Tooltip title={value} PopperProps={{ style: styles }}>
                <span>
                  {value}
                </span>
              </Tooltip>
            );
          }
          return value;
        };
      }

      return tempCol;
    });
  }

  checkAll = () => {
    const { data: { data = [] }, handleCheck } = this.props;
    const { checked = [] } = this.props;

    handleCheck(checked.length === data.length ? [] : data);
  }

  handleCheck = (data) => {
    const { handleCheck, checked } = this.props;
    const tempArr = [...checked];
    const elIndex = tempArr.findIndex(el => el.id === data.id);

    if (elIndex !== -1) {
      tempArr.splice(elIndex, 1);
    } else {
      tempArr.push(data);
    }

    handleCheck(tempArr);
  }

  fetchData = (props) => {
    const { fetchProps = {} } = this.state;
    const { page = 0, defaultPageSize = 10, sorted = [] } = props || fetchProps;
    const { getMethod } = this.props;
    const offset = page * defaultPageSize;
    const sort = {};

    if (props) {
      this.setState({ fetchProps: props });
    }

    if (sorted[0]) {
      const { desc, id } = sorted[0];
      sort.orderBy = id;
      sort.orderDirection = desc ? 'DESC' : 'ASC';
    }
    getMethod({ offset, limit: defaultPageSize, ...sort });
  }

  handleViewModal = () => {
    this.setState(({ isViewModalOpen }) => ({
      isViewModalOpen: !isViewModalOpen,
      disabled: true
    }));
  }

  saveAndToggleModal = () => {
    const { isViewModalOpen } = this.state;

    this.setState(({ isViewModalOpen }) => ({
      isViewModalOpen: !isViewModalOpen,
      disabled: true
    }), isViewModalOpen ? this.fetchData : undefined);
  }

  handleEntity = (entity) => {
    const { formatEntity } = this.props;
    this.setState({ entity: formatEntity(entity) });
  }

  startEdit = () => {
    this.setState({ disabled: false });
  }

  render() {
    const {
      data,
      isLoading,
      checked,
      ViewForm,
      currLang,
      ...rest
    } = this.props;
    const { isViewModalOpen, entity, disabled } = this.state;

    const { page } = data;

    return (
      <>
        <Dialog
          open={isViewModalOpen}
          onClose={this.handleViewModal}
          maxWidth="md"
        >
          <ViewForm
            initialValues={entity}
            disabled={disabled}
            startEdit={this.startEdit}
            edit
            saveAndToggleModal={this.saveAndToggleModal}
            toggleModal={this.handleViewModal}
          />
        </Dialog>
        <ReactTable
          data={data.data || []}
          loading={isLoading}
          pages={data.totalPages}
          page={page ? page - 1 : undefined}
          defaultPageSize={10}
          manual
          resizable={false}
          onFetchData={this.fetchData}
          getTableProps={() => ({ style: { display: 'block' } })}
          defaultSorted={[
            {
              id: 'id',
              desc: true
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onDoubleClick: () => {
              if (ViewForm) {
                this.handleViewModal();
                let entity = {};
                if (rowInfo.original.profile) {
                  entity = {
                    ...rowInfo.original.profile,
                    email: rowInfo.original.email,
                    roleId: rowInfo.original.roleId,
                    subscriptions: rowInfo.original.subscriptions
                  };
                } else {
                  entity = { ...rowInfo.original };
                }
                this.handleEntity(entity);
              }
            },
            className: {
              'active-row': rowInfo && !!checked.find(el => el.id === rowInfo.original.id),
              'cursor-pointer': !!ViewForm
            },
          })}
          PaginationComponent={props => Pagination({ ...props, currLang })}
          {...rest}
          columns={this.columns}
        />
      </>
    );
  }
}


Table.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  checked: PropTypes.arrayOf(PropTypes.object),
  onDoubleClick: PropTypes.func,
  getMethod: PropTypes.func,
  formatEntity: PropTypes.func,
  ViewForm: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool]),
  needCheck: PropTypes.bool,
  currLang: PropTypes.string.isRequired,
  handleCheck: PropTypes.func,
};

Table.defaultProps = {
  checked: [],
  onDoubleClick: () => null,
  ViewForm: '',
  getMethod: () => null,
  needCheck: true,
  handleCheck: () => null,
  formatEntity: entity => entity
};

export default connect(mapStateToProps, {})(Table);