import React from 'react';
import { TextField, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

let timer;

class Search extends React.Component {
  state = { search: '' }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  handleSearch = ({ target: { value } }) => {
    this.setState({ search: value }, () => {
      const { handleSearchValue } = this.props;
      handleSearchValue(value);
    });
    clearTimeout(timer);
    timer = setTimeout(this.search, 500);
  }

  search = () => {
    const { search } = this.state;
    const { getMethod, handleSearchValue } = this.props;

    handleSearchValue(search);
    getMethod({ offset: 0, limit: 10, q: search });
  }

  clearSearch = () => {
    this.setState({ search: '' }, this.search);
  }

  render() {
    const { search } = this.state;

    return (
      <div className="d-flex align-items-center">
        <TextField
          type="text"
          name="search"
          value={search}
          label={<Translate value="desktop.common.search_label" />}
          style={{ marginBottom: 15 }}
          onChange={this.handleSearch}
        />
        <IconButton size="small" onClick={this.clearSearch}>
          <i className="material-icons">
            clear
          </i>
        </IconButton>
      </div>
    );
  }
}

Search.propTypes = {
  getMethod: PropTypes.func.isRequired,
  handleSearchValue: PropTypes.func
};

Search.defaultProps = {
  handleSearchValue: () => null
};

export default Search;