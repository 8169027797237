import Client from './client';
import { LS_TOKEN_KEY, logout } from '../actions/auth';
import store from '../store';

let endpoint = process.env.REACT_APP_ENDPOINT;
if (endpoint && !endpoint.startsWith('http')) {
  endpoint = `https://${endpoint}`;
}
const baseUrl = endpoint || 'https://stage-api.tpoint.club';

const api = Client.create(baseUrl);

if (typeof window !== 'undefined') {
  const token = window.localStorage.getItem(LS_TOKEN_KEY);

  if (token) {
    api.setJwtToken(token);
  }
}

api.addErrorInterceptor((error) => {
  if (
    error.response
    && (error.response.status === 401 || error.response.status === 403)
    && localStorage.getItem('__jwtSecurerToken__')
  ) {
    localStorage.removeItem('__jwtSecurerToken__');
    // TODO dispatch logout
    store.dispatch(logout());
  }

  return Promise.reject(error);
});

export const BASE_URL = baseUrl;

export default api;
