import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';

const TextFieldComponent = ({ className, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      readOnly: true,
      className,
      style: { padding: 0 },
      endAdornment: (
        <InputAdornment>
          <i className="material-icons">
            arrow_drop_down
          </i>
        </InputAdornment>
      )

    }}
  />
);

TextFieldComponent.propTypes = {
  className: PropTypes.string,
};

TextFieldComponent.defaultProps = {
  className: '',
};

export default TextFieldComponent;