import mergeObjects from 'merge-objects';

import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  light: [],
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_COURT_GROUPS_LIGHT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_GROUPS_LIGHT_SUCCESS]: (state, { payload }) => ({
    ...state,
    light: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_GROUPS_LIGHT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_GROUPS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_GROUPS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_GROUPS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_COURT_GROUP_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_COURT_GROUP_SUCCESS]: state => ({
    ...state,
    light: [],
    error: null,
    isLoading: false,
  }),
  [c.CREATE_COURT_GROUP_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_COURT_GROUP_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_COURT_GROUP_SUCCESS]: (
    state,
    {
      payload: {
        schedule, specialSchedule, ...payload
      }
    }
  ) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map((el) => {
        if (el.id === payload.id) {
          const newPayload = mergeObjects(el, payload);
          return {
            ...newPayload,
            schedule,
            specialSchedule
          };
        }
        return el;
      })
    },
    light: [],
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_COURT_GROUP_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.DELETE_COURT_GROUP_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.DELETE_COURT_GROUP_SUCCESS]: state => ({
    ...state,
    light: [],
    error: null,
    isLoading: false,
  }),
  [c.DELETE_COURT_GROUP_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
