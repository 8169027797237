import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from '@material-ui/core';
import { I18n } from 'react-i18nify';

const DialogContext = createContext(null);

class DialogProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  }

  state = {
    isOpen: false,
    onConfirm: () => null,
    message: '',
    title: ''
  };

  showDialog = ({ onConfirm = () => null, message, title }) => {
    this.setState({
      isOpen: true, onConfirm, message, title
    });
  };

  hideDialog = () => {
    this.setState({ isOpen: false });
  };

  confirm = () => {
    const { onConfirm } = this.state;
    onConfirm();
    this.hideDialog();
  }

  render() {
    const { isOpen, message, title } = this.state;
    const { children } = this.props;

    return (
      <DialogContext.Provider value={{ showDialog: this.showDialog, hideDialog: this.hideDialog }}>
        {children}
        <Dialog
          open={isOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.hideDialog}
        >
          <DialogTitle>
            {title || I18n.t('desktop.common.are_you_sure_label')}
          </DialogTitle>
          <DialogContent>
            {message || I18n.t('desktop.common.are_you_sure_content')}
          </DialogContent>
          <DialogActions>
            <Button className="danger" onClick={this.hideDialog}>
              {I18n.t('desktop.common.no_btn')}
            </Button>
            <Button
              color="primary"
              className="btn-primary color-white"
              variant="contained"
              onClick={this.confirm}
            >
              {I18n.t('desktop.common.yes_btn')}
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContext.Provider>
    );
  }
}

const DialogConsumer = DialogContext.Consumer;

export {
  DialogProvider,
  DialogConsumer,
};
