/* eslint-disable */
import createReducer from './utils/createReducer';
import * as c from '../constants/actions';
import { ASSET_TYPES } from '../constants/utils';

const initialState = {
  data: {},
  logs: {},
  controllers: [],
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_ASSETS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_ASSETS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    controllers: payload.data.filter(({type}) => type === ASSET_TYPES.MAIN_CONTROLLER),
    error: null,
    isLoading: false,
  }),
  [c.GET_ASSETS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_ASSET_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_ASSET_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.CREATE_ASSET_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CREATE_DOOR_ASSET_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CREATE_DOOR_ASSET_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.CREATE_DOOR_ASSET_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_ASSET_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_ASSET_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map(el => (+el.id === payload.id
        ? payload
        : el
      ))
    },
    controllers: state.controllers.map(el => (+el.id === payload.id
        ? payload
        : el
      )),
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_ASSET_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_DOOR_ASSET_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.UPDATE_DOOR_ASSET_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      data: state.data.data.map(el => (el.id === payload.id
        ? payload
        : el
      ))
    },
    error: null,
    isLoading: false,
  }),
  [c.UPDATE_DOOR_ASSET_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.DELETE_ASSET_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.DELETE_ASSET_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.DELETE_ASSET_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CHANGE_ASSET_STATUS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CHANGE_ASSET_STATUS_SUCCESS]: state => ({
    ...state,
    error: null,
    isLoading: false,
  }),
  [c.CHANGE_ASSET_STATUS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_ASSETS_STATUS_HISTORY_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_ASSETS_STATUS_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    logs: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_ASSETS_STATUS_HISTORY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CHANGE_DOOR_ASSET_STATUS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.CHANGE_DOOR_ASSET_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    error: null,
    data: {
      ...state.data,
      data: state.data.data.map(el => (el.id === `${payload.asset_id}:${payload.id}`
        ? {...el, status: payload.status}
        : el
      ))
    },
    isLoading: false,
  }),
  [c.CHANGE_DOOR_ASSET_STATUS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
