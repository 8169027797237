import { toastr } from 'react-redux-toastr';

const toast = ({
  action, type, message, options = {}
}) => {
  let currentOptions = {
    removeOnHover: true,
    timeOut: 4000,
    ...options
  };

  if (type === 'error') {
    currentOptions = {
      removeOnHover: false,
      timeOut: 0,
      ...options,
    };
  }

  return toastr[type](action, message, currentOptions);
};

export default toast;
