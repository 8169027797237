import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

const CheckedOptions = ({
  actionMethod, checked, getId, options
}) => (
  <div className="checked-options">
    <div className="checked-label">
      <Translate value="desktop.common.actions_label" />
    </div>
    {options.length ? (
      <>
        {options.map(el => (
          <>
            {el}
          </>
        ))}
      </>
    ) : (
      <Button
        variant="text"
        className="secondary"
        onClick={() => {
          const ids = checked.map(el => el.id || getId(el));
          actionMethod({ ids });
        }}
      >
        <div className="disable-delete-option">
          <i className="material-icons">delete</i>
          <Translate value="desktop.common.delete_btn" />
        </div>
      </Button>
    )}
  </div>
);

CheckedOptions.propTypes = {
  actionMethod: PropTypes.func,
  checked: PropTypes.arrayOf(PropTypes.object),
  getId: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any)
};

CheckedOptions.defaultProps = {
  options: [],
  actionMethod: () => null,
  checked: [],
  getId: ({ id }) => id
};

export default CheckedOptions;