export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SOCIAL_LOGIN_REQUEST = 'SOCIAL_LOGIN_REQUEST';
export const SOCIAL_LOGIN_SUCCESS = 'SOCIAL_LOGIN_SUCCESS';
export const SOCIAL_LOGIN_ERROR = 'SOCIAL_LOGIN_ERROR';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const GET_OPERATORS_REQUEST = 'GET_OPERATORS_REQUEST';
export const GET_OPERATORS_SUCCESS = 'GET_OPERATORS_SUCCESS';
export const GET_OPERATORS_ERROR = 'GET_OPERATORS_ERROR';

export const GET_OPERATORS_LIGHT_REQUEST = 'GET_OPERATORS_LIGHT_REQUEST';
export const GET_OPERATORS_LIGHT_SUCCESS = 'GET_OPERATORS_LIGHT_SUCCESS';
export const GET_OPERATORS_LIGHT_ERROR = 'GET_OPERATORS_LIGHT_ERROR';

export const CREATE_OPERATOR_REQUEST = 'CREATE_OPERATOR_REQUEST';
export const CREATE_OPERATOR_SUCCESS = 'CREATE_OPERATOR_SUCCESS';
export const CREATE_OPERATOR_ERROR = 'CREATE_OPERATOR_ERROR';

export const UPDATE_OPERATOR_REQUEST = 'UPDATE_OPERATOR_REQUEST';
export const UPDATE_OPERATOR_SUCCESS = 'UPDATE_OPERATOR_SUCCESS';
export const UPDATE_OPERATOR_ERROR = 'UPDATE_OPERATOR_ERROR';

export const DELETE_OPERATOR_REQUEST = 'DELETE_OPERATOR_REQUEST';
export const DELETE_OPERATOR_SUCCESS = 'DELETE_OPERATOR_SUCCESS';
export const DELETE_OPERATOR_ERROR = 'DELETE_OPERATOR_ERROR';

export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';

export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';

export const DELETE_SUBSCRIPTION_REQUEST = 'DELETE_SUBSCRIPTION_REQUEST';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';

export const GET_COURT_GROUPS_LIGHT_REQUEST = 'GET_COURT_GROUPS_LIGHT_REQUEST';
export const GET_COURT_GROUPS_LIGHT_SUCCESS = 'GET_COURT_GROUPS_LIGHT_SUCCESS';
export const GET_COURT_GROUPS_LIGHT_ERROR = 'GET_COURT_GROUPS_LIGHT_ERROR';

export const GET_COURT_GROUPS_REQUEST = 'GET_COURT_GROUPS_REQUEST';
export const GET_COURT_GROUPS_SUCCESS = 'GET_COURT_GROUPS_SUCCESS';
export const GET_COURT_GROUPS_ERROR = 'GET_COURT_GROUPS_ERROR';

export const CREATE_COURT_GROUP_REQUEST = 'CREATE_COURT_GROUP_REQUEST';
export const CREATE_COURT_GROUP_SUCCESS = 'CREATE_COURT_GROUP_SUCCESS';
export const CREATE_COURT_GROUP_ERROR = 'CREATE_COURT_GROUP_ERROR';

export const UPDATE_COURT_GROUP_REQUEST = 'UPDATE_COURT_GROUP_REQUEST';
export const UPDATE_COURT_GROUP_SUCCESS = 'UPDATE_COURT_GROUP_SUCCESS';
export const UPDATE_COURT_GROUP_ERROR = 'UPDATE_COURT_GROUP_ERROR';

export const DELETE_COURT_GROUP_REQUEST = 'DELETE_COURT_GROUP_REQUEST';
export const DELETE_COURT_GROUP_SUCCESS = 'DELETE_COURT_GROUP_SUCCESS';
export const DELETE_COURT_GROUP_ERROR = 'DELETE_COURT_GROUP_ERROR';

export const GET_COURTS_LIGHT_REQUEST = 'GET_COURTS_LIGHT_REQUEST';
export const GET_COURTS_LIGHT_SUCCESS = 'GET_COURTS_LIGHT_SUCCESS';
export const GET_COURTS_LIGHT_ERROR = 'GET_COURTS_LIGHT_ERROR';

export const GET_COURTS_REQUEST = 'GET_COURTS_REQUEST';
export const GET_COURTS_SUCCESS = 'GET_COURTS_SUCCESS';
export const GET_COURTS_ERROR = 'GET_COURTS_ERROR';

export const CREATE_COURT_REQUEST = 'CREATE_COURT_REQUEST';
export const CREATE_COURT_SUCCESS = 'CREATE_COURT_SUCCESS';
export const CREATE_COURT_ERROR = 'CREATE_COURT_ERROR';

export const UPDATE_COURT_REQUEST = 'UPDATE_COURT_REQUEST';
export const UPDATE_COURT_SUCCESS = 'UPDATE_COURT_SUCCESS';
export const UPDATE_COURT_ERROR = 'UPDATE_COURT_ERROR';

export const DELETE_COURT_REQUEST = 'DELETE_COURT_REQUEST';
export const DELETE_COURT_SUCCESS = 'DELETE_COURT_SUCCESS';
export const DELETE_COURT_ERROR = 'DELETE_COURT_ERROR';

export const GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_ERROR = 'GET_ASSETS_ERROR';

export const GET_ASSETS_STATUS_HISTORY_REQUEST = 'GET_ASSETS_STATUS_HISTORY_REQUEST';
export const GET_ASSETS_STATUS_HISTORY_SUCCESS = 'GET_ASSETS_STATUS_HISTORY_SUCCESS';
export const GET_ASSETS_STATUS_HISTORY_ERROR = 'GET_ASSETS_STATUS_HISTORY_ERROR';

export const CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_ERROR = 'CREATE_ASSET_ERROR';

export const UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_ERROR = 'UPDATE_ASSET_ERROR';

export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_ERROR = 'DELETE_ASSET_ERROR';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_ERROR = 'GET_USER_ROLES_ERROR';

export const GET_ACTIVITY_LOGS_REQUEST = 'GET_ACTIVITY_LOGS_REQUEST';
export const GET_ACTIVITY_LOGS_SUCCESS = 'GET_ACTIVITY_LOGS_SUCCESS';
export const GET_ACTIVITY_LOGS_ERROR = 'GET_ACTIVITY_LOGS_ERROR';

export const GET_TRANSLATIONS_REQUEST = 'GET_TRANSLATIONS_REQUEST';
export const GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATIONS_SUCCESS';
export const GET_TRANSLATIONS_ERROR = 'GET_TRANSLATIONS_ERROR';

export const SAVE_TRANSLATION_REQUEST = 'SAVE_TRANSLATION_REQUEST';
export const SAVE_TRANSLATION_SUCCESS = 'SAVE_TRANSLATION_SUCCESS';
export const SAVE_TRANSLATION_ERROR = 'SAVE_TRANSLATION_ERROR';

export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR';

export const GET_USER_LIGHT_REQUEST = 'GET_USER_LIGHT_REQUEST';
export const GET_USER_LIGHT_SUCCESS = 'GET_USER_LIGHT_SUCCESS';
export const GET_USER_LIGHT_ERROR = 'GET_USER_LIGHT_ERROR';

export const CHANGE_ASSET_STATUS_REQUEST = 'CHANGE_ASSET_STATUS_REQUEST';
export const CHANGE_ASSET_STATUS_SUCCESS = 'CHANGE_ASSET_STATUS_SUCCESS';
export const CHANGE_ASSET_STATUS_ERROR = 'CHANGE_ASSET_STATUS_ERROR';

export const GET_CLUB_PARAMETERS_REQUEST = 'GET_CLUB_PARAMETERS_REQUEST';
export const GET_CLUB_PARAMETERS_SUCCESS = 'GET_CLUB_PARAMETERS_SUCCESS';
export const GET_CLUB_PARAMETERS_ERROR = 'GET_CLUB_PARAMETERS_ERROR';

export const SAVE_CLUB_PARAMETERS_REQUEST = 'SAVE_CLUB_PARAMETERS_REQUEST';
export const SAVE_CLUB_PARAMETERS_SUCCESS = 'SAVE_CLUB_PARAMETERS_SUCCESS';
export const SAVE_CLUB_PARAMETERS_ERROR = 'SAVE_CLUB_PARAMETERS_ERROR';

export const CHANGE_CURR_LANGUAGE = 'CHANGE_CURR_LANGUAGE';

export const SET_COURT_SETTING_REQUEST = 'SET_COURT_SETTING_REQUEST';
export const SET_COURT_SETTING_SUCCESS = 'SET_COURT_SETTING_SUCCESS';
export const SET_COURT_SETTING_ERROR = 'SET_COURT_SETTING_ERROR';

export const SET_COURT_OPTIMAL_LIGHTS_REQUEST = 'SET_COURT_OPTIMAL_LIGHTS_REQUEST';
export const SET_COURT_OPTIMAL_LIGHTS_SUCCESS = 'SET_COURT_OPTIMAL_LIGHTS_SUCCESS';
export const SET_COURT_OPTIMAL_LIGHTS_ERROR = 'SET_COURT_OPTIMAL_LIGHTS_ERROR';

export const GET_COURT_SETTING_REQUEST = 'GET_COURT_SETTING_REQUEST';
export const GET_COURT_SETTING_SUCCESS = 'GET_COURT_SETTING_SUCCESS';
export const GET_COURT_SETTING_ERROR = 'GET_COURT_SETTING_ERROR';

export const GET_COURT_AIR_SETTING_REQUEST = 'GET_COURT_AIR_SETTING_REQUEST';
export const GET_COURT_AIR_SETTING_SUCCESS = 'GET_COURT_AIR_SETTING_SUCCESS';
export const GET_COURT_AIR_SETTING_ERROR = 'GET_COURT_AIR_SETTING_ERROR';

export const GET_REVENUE_REPORT_REQUEST = 'GET_REVENUE_REPORT_REQUEST';
export const GET_REVENUE_REPORT_SUCCESS = 'GET_REVENUE_REPORT_SUCCESS';
export const GET_REVENUE_REPORT_ERROR = 'GET_REVENUE_REPORT_ERROR';

export const GET_COURT_USAGE_REPORT_REQUEST = 'GET_COURT_USAGE_REPORT_REQUEST';
export const GET_COURT_USAGE_REPORT_SUCCESS = 'GET_COURT_USAGE_REPORT_SUCCESS';
export const GET_COURT_USAGE_REPORT_ERROR = 'GET_COURT_USAGE_REPORT_ERROR';

export const GET_COURT_USAGE_TABLE_REPORT_REQUEST = 'GET_COURT_USAGE_TABLE_REPORT_REQUEST';
export const GET_COURT_USAGE_TABLE_REPORT_SUCCESS = 'GET_COURT_USAGE_TABLE_REPORT_SUCCESS';
export const GET_COURT_USAGE_TABLE_REPORT_ERROR = 'GET_COURT_USAGE_TABLE_REPORT_ERROR';

export const GET_TOP_PLAYERS_REPORT_REQUEST = 'GET_TOP_PLAYERS_REPORT_REQUEST';
export const GET_TOP_PLAYERS_REPORT_SUCCESS = 'GET_TOP_PLAYERS_REPORT_SUCCESS';
export const GET_TOP_PLAYERS_REPORT_ERROR = 'GET_TOP_PLAYERS_REPORT_ERROR';

export const GET_RESERVATIONS_REQUEST = 'GET_RESERVATIONS_REQUEST';
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_ERROR = 'GET_RESERVATIONS_ERROR';

export const GET_ALL_ENTITIES_REQUEST = 'GET_ALL_ENTITIES_REQUEST';
export const GET_ALL_ENTITIES_SUCCESS = 'GET_ALL_ENTITIES_SUCCESS';
export const GET_ALL_ENTITIES_ERROR = 'GET_ALL_ENTITIES_ERROR';

export const GET_COURT_UTILIZATION_REPORT_REQUEST = 'GET_COURT_UTILIZATION_REPORT_REQUEST';
export const GET_COURT_UTILIZATION_REPORT_SUCCESS = 'GET_COURT_UTILIZATION_REPORT_SUCCESS';
export const GET_COURT_UTILIZATION_REPORT_ERROR = 'GET_COURT_UTILIZATION_REPORT_ERROR';

export const GET_COURT_UTILIZATION_TABLE_REPORT_REQUEST = 'GET_COURT_UTILIZATION_TABLE_REPORT_REQUEST';
export const GET_COURT_UTILIZATION_TABLE_REPORT_SUCCESS = 'GET_COURT_UTILIZATION_TABLE_REPORT_SUCCESS';
export const GET_COURT_UTILIZATION_TABLE_REPORT_ERROR = 'GET_COURT_UTILIZATION_TABLE_REPORT_ERROR';

export const CREATE_RESERVATION_REQUEST = 'CREATE_RESERVATION_REQUEST';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_ERROR = 'CREATE_RESERVATION_ERROR';

export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_ERROR = 'UPDATE_RESERVATION_ERROR';

export const DELETE_RESERVATION_REQUEST = 'DELETE_RESERVATION_REQUEST';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_ERROR = 'DELETE_RESERVATION_ERROR';

export const START_OR_END_GAME_REQUEST = 'START_OR_END_GAME_REQUEST';
export const START_OR_END_GAME_SUCCESS = 'START_OR_END_GAME_SUCCESS';
export const START_OR_END_GAME_ERROR = 'START_OR_END_GAME_ERROR';

export const GET_PAYMENT_REPORT_REQUEST = 'GET_PAYMENT_REPORT_REQUEST';
export const GET_PAYMENT_REPORT_SUCCESS = 'GET_PAYMENT_REPORT_SUCCESS';
export const GET_PAYMENT_REPORT_ERROR = 'GET_PAYMENT_REPORT_ERROR';

export const GET_PAYMENT_TABLE_REPORT_REQUEST = 'GET_PAYMENT_TABLE_REPORT_REQUEST';
export const GET_PAYMENT_TABLE_REPORT_SUCCESS = 'GET_PAYMENT_TABLE_REPORT_SUCCESS';
export const GET_PAYMENT_TABLE_REPORT_ERROR = 'GET_PAYMENT_TABLE_REPORT_ERROR';

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';

export const UPDATE_MESSAGE_REQUEST = 'UPDATE_MESSAGE_REQUEST';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';

export const GET_AUDIO_MESSAGES_REQUEST = 'GET_AUDIO_MESSAGES_REQUEST';
export const GET_AUDIO_MESSAGES_SUCCESS = 'GET_AUDIO_MESSAGES_SUCCESS';
export const GET_AUDIO_MESSAGES_ERROR = 'GET_AUDIO_MESSAGES_ERROR';

export const UPDATE_AUDIO_MESSAGE_REQUEST = 'UPDATE_AUDIO_MESSAGE_REQUEST';
export const UPDATE_AUDIO_MESSAGE_SUCCESS = 'UPDATE_AUDIO_MESSAGE_SUCCESS';
export const UPDATE_AUDIO_MESSAGE_ERROR = 'UPDATE_AUDIO_MESSAGE_ERROR';

export const GET_USER_FRIENDS_REQUEST = 'GET_USER_FRIENDS_REQUEST';
export const GET_USER_FRIENDS_SUCCESS = 'GET_USER_FRIENDS_SUCCESS';
export const GET_USER_FRIENDS_ERROR = 'GET_USER_FRIENDS_ERROR';

export const SET_USER_FREE_PLAN_REQUEST = 'SET_USER_FREE_PLAN_REQUEST';
export const SET_USER_FREE_PLAN_SUCCESS = 'SET_USER_FREE_PLAN_SUCCESS';
export const SET_USER_FREE_PLAN_ERROR = 'SET_USER_FREE_PLAN_ERROR';

export const REMOVE_USER_FREE_PLAN_REQUEST = 'REMOVE_USER_FREE_PLAN_REQUEST';
export const REMOVE_USER_FREE_PLAN_SUCCESS = 'REMOVE_USER_FREE_PLAN_SUCCESS';
export const REMOVE_USER_FREE_PLAN_ERROR = 'REMOVE_USER_FREE_PLAN_ERROR';

export const GET_SYSTEM_PARAMETERS_REQUEST = 'GET_SYSTEM_PARAMETERS_REQUEST';
export const GET_SYSTEM_PARAMETERS_SUCCESS = 'GET_SYSTEM_PARAMETERS_SUCCESS';
export const GET_SYSTEM_PARAMETERS_ERROR = 'GET_SYSTEM_PARAMETERS_ERROR';

export const SAVE_SYSTEM_PARAMETERS_REQUEST = 'SAVE_SYSTEM_PARAMETERS_REQUEST';
export const SAVE_SYSTEM_PARAMETERS_SUCCESS = 'SAVE_SYSTEM_PARAMETERS_SUCCESS';
export const SAVE_SYSTEM_PARAMETERS_ERROR = 'SAVE_SYSTEM_PARAMETERS_ERROR';

export const GET_REVENUE_REPORT_SUMMARY_REQUEST = 'GET_REVENUE_REPORT_SUMMARY_REQUEST';
export const GET_REVENUE_REPORT_SUMMARY_SUCCESS = 'GET_REVENUE_REPORT_SUMMARY_SUCCESS';
export const GET_REVENUE_REPORT_SUMMARY_ERROR = 'GET_REVENUE_REPORT_SUMMARY_ERROR';

export const GET_COURT_UTILIZATION_REPORT_SUMMARY_REQUEST = 'GET_COURT_UTILIZATION_REPORT_SUMMARY_REQUEST';
export const GET_COURT_UTILIZATION_REPORT_SUMMARY_SUCCESS = 'GET_COURT_UTILIZATION_REPORT_SUMMARY_SUCCESS';
export const GET_COURT_UTILIZATION_REPORT_SUMMARY_ERROR = 'GET_COURT_UTILIZATION_REPORT_SUMMARY_ERROR';

export const GET_COURT_USAGE_REPORT_SUMMARY_REQUEST = 'GET_COURT_USAGE_REPORT_SUMMARY_REQUEST';
export const GET_COURT_USAGE_REPORT_SUMMARY_SUCCESS = 'GET_COURT_USAGE_REPORT_SUMMARY_SUCCESS';
export const GET_COURT_USAGE_REPORT_SUMMARY_ERROR = 'GET_COURT_USAGE_REPORT_SUMMARY_ERROR';

export const SET_COURT_AIR_SETTING_REQUEST = 'SET_COURT_AIR_SETTING_REQUEST';
export const SET_COURT_AIR_SETTING_SUCCESS = 'SET_COURT_AIR_SETTING_SUCCESS';
export const SET_COURT_AIR_SETTING_ERROR = 'SET_COURT_AIR_SETTING_ERROR';

export const SET_COURT_AUTOMATA_SETTING_REQUEST = 'SET_COURT_AUTOMATA_SETTING_REQUEST';
export const SET_COURT_AUTOMATA_SETTING_SUCCESS = 'SET_COURT_AUTOMATA_SETTING_SUCCESS';
export const SET_COURT_AUTOMATA_SETTING_ERROR = 'SET_COURT_AUTOMATA_SETTING_ERROR';

export const BATCH_EDIT_USERS_STATUS_REQUEST = 'BATCH_EDIT_USERS_STATUS_REQUEST';
export const BATCH_EDIT_USERS_STATUS_SUCCESS = 'BATCH_EDIT_USERS_STATUS_SUCCESS';
export const BATCH_EDIT_USERS_STATUS_ERROR = 'BATCH_EDIT_USERS_STATUS_ERROR';

export const GET_COUNTRIES_LIST_REQUEST = 'GET_COUNTRIES_LIST_REQUEST';
export const GET_COUNTRIES_LIST_SUCCESS = 'GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_ERROR = 'GET_COUNTRIES_LIST_ERROR';

export const HAVE_COURT_ACTIVE_GAME_REQUEST = 'HAVE_COURT_ACTIVE_GAME_REQUEST';
export const HAVE_COURT_ACTIVE_GAME_SUCCESS = 'HAVE_COURT_ACTIVE_GAME_SUCCESS';
export const HAVE_COURT_ACTIVE_GAME_ERROR = 'HAVE_COURT_ACTIVE_GAME_ERROR';

export const SAVE_COURT_GROUP_SCHEDULE_REQUEST = 'SAVE_COURT_GROUP_SCHEDULE_REQUEST';
export const SAVE_COURT_GROUP_SCHEDULE_SUCCESS = 'SAVE_COURT_GROUP_SCHEDULE_SUCCESS';
export const SAVE_COURT_GROUP_SCHEDULE_ERROR = 'SAVE_COURT_GROUP_SCHEDULE_ERROR';

export const DELETE_COURT_GROUP_SPECIAL_DAY_REQUEST = 'DELETE_COURT_GROUP_SPECIAL_DAY_REQUEST';
export const DELETE_COURT_GROUP_SPECIAL_DAY_SUCCESS = 'DELETE_COURT_GROUP_SPECIAL_DAY_SUCCESS';
export const DELETE_COURT_GROUP_SPECIAL_DAY_ERROR = 'DELETE_COURT_GROUP_SPECIAL_DAY_ERROR';

export const GET_COURT_GROUP_BY_ID_REQUEST = 'GET_COURT_GROUP_BY_ID_REQUEST';
export const GET_COURT_GROUP_BY_ID_SUCCESS = 'GET_COURT_GROUP_BY_ID_SUCCESS';
export const GET_COURT_GROUP_BY_ID_ERROR = 'GET_COURT_GROUP_BY_ID_ERROR';

export const GET_COURT_AUTOMATA_STATE_REQUEST = 'GET_COURT_AUTOMATA_STATE_REQUEST';
export const GET_COURT_AUTOMATA_STATE_SUCCESS = 'GET_COURT_AUTOMATA_STATE_SUCCESS';
export const GET_COURT_AUTOMATA_STATE_ERROR = 'GET_COURT_AUTOMATA_STATE_ERROR';

export const CREATE_DOOR_ASSET_REQUEST = 'CREATE_DOOR_ASSET_REQUEST';
export const CREATE_DOOR_ASSET_SUCCESS = 'CREATE_DOOR_ASSET_SUCCESS';
export const CREATE_DOOR_ASSET_ERROR = 'CREATE_DOOR_ASSET_ERROR';

export const UPDATE_DOOR_ASSET_REQUEST = 'UPDATE_DOOR_ASSET_REQUEST';
export const UPDATE_DOOR_ASSET_SUCCESS = 'UPDATE_DOOR_ASSET_SUCCESS';
export const UPDATE_DOOR_ASSET_ERROR = 'UPDATE_DOOR_ASSET_ERROR';

export const CHANGE_DOOR_ASSET_STATUS_REQUEST = 'CHANGE_DOOR_ASSET_STATUS_REQUEST';
export const CHANGE_DOOR_ASSET_STATUS_SUCCESS = 'CHANGE_DOOR_ASSET_STATUS_SUCCESS';
export const CHANGE_DOOR_ASSET_STATUS_ERROR = 'CHANGE_DOOR_ASSET_STATUS_ERROR';