import React, { lazy, Suspense } from 'react';
import {
  Router, Switch, Redirect, Route
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MainLayoutRoute from './main-layout-route';
import TranslationsLayout from '../layouts/translations-layout';
import history from '../history';
import { roles } from '../constants/utils';

const LazyForgotPassword = lazy(() => import('../containers/forgot-password'));
const LazyAuthContainer = lazy(() => import('../containers/auth-container'));
const LazyDashboardContainer = lazy(() => import('../containers/dashboard'));
const LazyOperators = lazy(() => import('../containers/operators'));
const LazySubPlans = lazy(() => import('../containers/subscription-plan'));
const LazyCourtGroups = lazy(() => import('../containers/court-groups'));
const LazyCourts = lazy(() => import('../containers/courts'));
const LazyResetPassword = lazy(() => import('../containers/reset-password'));
const LazyAssets = lazy(() => import('../containers/assets'));
const LazyUsers = lazy(() => import('../containers/users'));
const LazyActivityLogs = lazy(() => import('../containers/activity-log'));
const LazyTranslations = lazy(() => import('../containers/translations'));
const LazySystemParameters = lazy(() => import('../containers/club-params'));
const LazyAssetsLogs = lazy(() => import('../containers/assets-logs'));
const LazyReservations = lazy(() => import('../containers/reservations'));
const LazyMessages = lazy(() => import('../containers/messages'));
const LazyAccountSettings = lazy(() => import('../containers/account-settings'));
const LazyResetPasswordUser = lazy(() => import('../containers/reset-password-player'));
const LazyDownloadApps = lazy(() => import('../containers/download-apps'));

const Routes = ({ isAuthenticated }) => (
  <Router history={history}>
    <Switch>
      <>
        <TranslationsLayout>
          <Route
            exact
            path="/"
            render={({ isAuthenticated }) => <Redirect to={isAuthenticated ? '/dashboard' : '/auth'} />}
          />

          <Route
            path="/auth"
            exact
            render={matchprops => (isAuthenticated
              ? <Redirect to="/dashboard" />
              : (
                <Suspense fallback={null}>
                  <LazyAuthContainer {...matchprops} />
                </Suspense>
              )
            )}
          />

          <Route
            path="/reset-password"
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyResetPassword {...matchprops} />
              </Suspense>
            )}
          />

          <Route
            path="/player-reset-password"
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyResetPasswordUser {...matchprops} />
              </Suspense>
            )}
          />

          <Route
            path="/player-download"
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyDownloadApps {...matchprops} />
              </Suspense>
            )}
          />
          <Route
            path="/forgot-password"
            exact
            render={matchprops => (isAuthenticated
              ? <Redirect to="/dashboard" />
              : (
                <Suspense fallback={null}>
                  <LazyForgotPassword {...matchprops} />
                </Suspense>
              )
            )}
          />

          <MainLayoutRoute
            path="/dashboard"
            exact
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyDashboardContainer {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/operators"
            accessRoles={[roles.ADMIN]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyOperators {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/sub-plans"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazySubPlans {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/court-groups"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyCourtGroups {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/courts"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyCourts {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/assets"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyAssets {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/users"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyUsers {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/activity-logs"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyActivityLogs {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/club-params"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazySystemParameters {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/asset-logs"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyAssetsLogs {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/reservations"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyReservations {...matchprops} />
              </Suspense>
            )}
          />

          <MainLayoutRoute
            path="/account-settings"
            accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
            exact
            render={matchprops => (
              <Suspense fallback={null}>
                <LazyAccountSettings {...matchprops} />
              </Suspense>
            )}
          />
        </TranslationsLayout>

        <MainLayoutRoute
          path="/messages"
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER]}
          exact
          render={matchprops => (
            <Suspense fallback={null}>
              <LazyMessages {...matchprops} />
            </Suspense>
          )}
        />

        <MainLayoutRoute
          path="/translations"
          accessRoles={[roles.ADMIN]}
          exact
          render={matchprops => (
            <Suspense fallback={null}>
              <LazyTranslations {...matchprops} />
            </Suspense>
          )}
        />

        {/* informative pdf files */}
        <Route
          exact
          path="/privacy"
          render={() => (
            <Suspense fallback={null}>
              <object width="100%" height="100%" data="https://squash-terms.s3-eu-west-1.amazonaws.com/Tpoint+Privacy+Notice.pdf" type="application/pdf">   </object>
            </Suspense>
          )}
        />

        <Route
          exact
          path="/terms-of-use"
          render={() => (
            <Suspense fallback={null}>
              <object width="100%" height="100%" data="https://squash-terms.s3-eu-west-1.amazonaws.com/Tpoint+-+Terms+of+Use.pdf" type="application/pdf">   </object>
            </Suspense>
          )}
        />

      </>
    </Switch>
  </Router>
);

Routes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated
});


export default connect(mapStateToProps, {})(Routes);
