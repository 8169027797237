// eslint-disable-next-line import/prefer-default-export
export const getSuggestionSearchTerms = (suggestion, accessors) => {
  let searchTerm = '';
  // eslint-disable-next-line no-unused-vars
  for (const accessor of accessors) {
    const value = accessor
      .split('.')
      .reduce((acc, part) => acc && acc[part], suggestion);

    if (value) {
      searchTerm += value;
    }
  }

  return searchTerm;
};

export const contains = (string, subString) => {
  const [normalizedString, normalizedSubString] = [string, subString]
    .map(value => String(value).trim().toLowerCase());

  return normalizedString.indexOf(normalizedSubString.toLowerCase()) > -1;
};

export const startWith = (string, subString) => {
  const [normalizedString, normalizedSubString] = [string, subString]
    .map(value => String(value).trim().toLowerCase());

  return normalizedString.startsWith(normalizedSubString);
};