export default {
  getRoles() {
    return {
      method: 'GET',
      url: '/profile/roles',
    };
  },

  login(data) {
    return {
      method: 'POST',
      url: '/identity/login',
      data,
    };
  },

  socialLogin({ accessToken, provider }) {
    return {
      method: 'POST',
      url: `/identity/social-login/${provider}`,
      data: {
        access_token: accessToken,
        provider
      },
    };
  },

  logout() {
    return {
      method: 'GET',
      url: '/identity/logout',
    };
  },

  register(data) {
    return {
      method: 'POST',
      url: '/identity/register',
      data,
    };
  },

  getUserProfile() {
    return {
      method: 'GET',
      url: '/identity/me'
    };
  },

  updateUserProfile({ id, data }) {
    return {
      method: 'PATCH',
      url: `/profile/${id}`,
      data,
    };
  },

  forgotPassword(data) {
    return {
      method: 'POST',
      url: '/identity/forgotPassword',
      data
    };
  },


  resetPassword(data) {
    return {
      method: 'POST',
      url: '/identity/resetPassword',
      data
    };
  },

  getOperators(data) {
    return {
      method: 'GET',
      url: '/operator',
      data
    };
  },

  getOperatorsLight(id) {
    return {
      method: 'GET',
      url: `/operator/light${typeof id === 'number' ? `/${id}` : ''}`,
    };
  },

  createOperator(data) {
    return {
      method: 'POST',
      url: '/operator',
      data
    };
  },

  updateOperator(data) {
    return {
      method: 'PATCH',
      url: `/operator/${data.id}`,
      data
    };
  },

  deleteOperator(data) {
    return {
      method: 'DELETE',
      url: '/operator/batch',
      data
    };
  },

  getSubscriptionPlans(data) {
    return {
      method: 'GET',
      url: '/subscription-plan',
      data
    };
  },

  createSubscriptionPlan(data) {
    return {
      method: 'POST',
      url: '/subscription-plan',
      data
    };
  },

  updateSubscriptionPlan(data) {
    return {
      method: 'PATCH',
      url: `/subscription-plan/${data.id}`,
      data
    };
  },

  deleteSubscriptionPlan(data) {
    return {
      method: 'DELETE',
      url: '/subscription-plan/batch',
      data
    };
  },

  getCourtGroupsLight(data) {
    return {
      method: 'GET',
      url: '/court-group/light',
      data
    };
  },

  getCourtGroups(data) {
    return {
      method: 'GET',
      url: '/court-group',
      data
    };
  },

  createCourtGroup(data) {
    return {
      method: 'POST',
      url: '/court-group',
      data
    };
  },

  updateCourtGroup(data) {
    return {
      method: 'PUT',
      url: `/court-group/${data.id}`,
      data
    };
  },

  deleteCourtGroup(data) {
    return {
      method: 'DELETE',
      url: '/court-group/batch',
      data
    };
  },

  getCourtsLight(data) {
    return {
      method: 'GET',
      url: '/court/light',
      data
    };
  },

  getCourts(data) {
    return {
      method: 'GET',
      url: '/court',
      data
    };
  },

  createCourt(data) {
    return {
      method: 'POST',
      url: '/court',
      data
    };
  },

  updateCourt(data) {
    return {
      method: 'PUT',
      url: `/court/${data.id}`,
      data
    };
  },

  deleteCourt(data) {
    return {
      method: 'DELETE',
      url: '/court/batch',
      data
    };
  },

  getAssets(data) {
    return {
      method: 'GET',
      url: '/asset',
      data
    };
  },

  getAssetsStatusHistory(data) {
    return {
      method: 'GET',
      url: '/asset/log',
      data
    };
  },

  createAsset(data) {
    return {
      method: 'POST',
      url: '/asset',
      data
    };
  },

  createDoorAsset(data) {
    return {
      method: 'POST',
      url: '/asset/assetdoor',
      data
    };
  },

  updateAsset(data) {
    return {
      method: 'PUT',
      url: `/asset/${data.id}`,
      data
    };
  },

  updateDoorAsset(data) {
    return {
      method: 'PUT',
      url: `/asset/assetdoor/${data.id.split(':')[1]}`,
      data
    };
  },

  deleteDoorAsset(id) {
    return {
      method: 'DELETE',
      url: `/asset/assetdoor/${id.split(':')[1]}`,
    };
  },

  deleteAsset(data) {
    return {
      method: 'DELETE',
      url: '/asset/batch',
      data
    };
  },

  getUsers(data) {
    return {
      method: 'GET',
      url: '/profile/list',
      data
    };
  },

  createUser(data) {
    return {
      method: 'POST',
      url: '/profile/add',
      data
    };
  },

  updateUser(data) {
    return {
      method: 'PATCH',
      url: `/profile/${data.id}`,
      data
    };
  },

  deleteUser(id) {
    return {
      method: 'DELETE',
      url: `/profile/identity/${id}`
    };
  },

  setUserFreePlan(data) {
    return {
      method: 'POST',
      url: '/subscription/free',
      data
    };
  },

  removeUserFreePlan(data) {
    return {
      method: 'DELETE',
      url: '/subscription/remove-free',
      data
    };
  },

  getActivityLogs(data) {
    return {
      method: 'GET',
      url: '/activity-log',
      data
    };
  },

  getPaymentMethods() {
    return {
      method: 'GET',
      url: '/payment-method',
    };
  },

  getUsersLight(data) {
    return {
      method: 'GET',
      url: '/profile/light',
      data
    };
  },

  changeAssetStatus(data) {
    return {
      method: 'POST',
      url: `/asset/${data.assetId}/log`,
      data
    };
  },

  changeDoorAssetStatus(data) {
    return {
      method: 'PUT',
      url: `/asset/assetdoor/${data.id.split(':')[1]}`,
      data
    };
  },

  getClubParameters(data) {
    return {
      method: 'GET',
      url: '/court-parameter',
      data
    };
  },

  saveClubParameters(data) {
    return {
      method: 'POST',
      url: '/court-parameter',
      data
    };
  },

  getTranslations() {
    return {
      method: 'GET',
      url: '/translation/all'
    };
  },

  saveTranslation(data) {
    return {
      method: 'PATCH',
      url: `/translation/${data.lang}/bulk`,
      data: data.content
    };
  },

  batchDeleteOperators(data) {
    return {
      method: 'DELETE',
      url: '/operator/batch',
      data
    };
  },

  courtSettings(data) {
    return {
      method: 'POST',
      url: '/tpoint/rpc',
      data
    };
  },

  getRevenueReport(data) {
    return {
      method: 'GET',
      url: '/report/revenue',
      data
    };
  },

  getRevenueReportSummary(data) {
    return {
      method: 'GET',
      url: '/report/revenue/sum',
      data
    };
  },

  getCourtUsageReport(data) {
    const rangeLegends = {
      day: 'month',
      month: 'year',
      year: 'years'
    };
    return {
      method: 'GET',
      url: `/report/court-usage/${data.courtId}`,
      data: {
        ...data,
        groupBy: rangeLegends[data.groupBy]
      }
    };
  },

  getCourtUsageTableReport(data) {
    return {
      method: 'GET',
      url: `/report/court-usage/${data.courtId}`,
      data: {
        ...data,
        groupBy: data.groupBy
      }
    };
  },

  getTopPlayersReport(data) {
    return {
      method: 'GET',
      url: '/report/user-usage',
      data
    };
  },

  getReservations(data) {
    return {
      method: 'GET',
      url: '/reservation',
      data
    };
  },

  createReservation(data) {
    return {
      method: 'POST',
      url: '/reservation',
      data
    };
  },

  updateReservation(data) {
    return {
      method: 'PUT',
      url: `/reservation/${data.id}/admin`,
      data
    };
  },

  deleteReservation(id) {
    return {
      method: 'DELETE',
      url: `/reservation/${id}`
    };
  },

  startGame({ id, code }) {
    return {
      method: 'POST',
      url: `/reservation/${id}/start`,
      data: { code }
    };
  },

  endGame(id) {
    return {
      method: 'POST',
      url: `/reservation/${id}/end`
    };
  },

  getPaymentReport(data) {
    return {
      method: 'GET',
      url: '/report/payments/summary',
      data
    };
  },

  getPaymentTableReport(data) {
    return {
      method: 'GET',
      url: '/report/payments',
      data
    };
  },

  getMessages(data) {
    return {
      method: 'GET',
      url: '/translation/all',
      data
    };
  },

  updateMessage(data) {
    return {
      method: 'PATCH',
      url: `/translation/${data.lang}/bulk`,
      data: data.content
    };
  },

  getUserFriends(data) {
    return {
      method: 'GET',
      url: '/profile/friends',
      data
    };
  },

  getSystemParameters(data) {
    return {
      method: 'GET',
      url: '/court-parameter/system',
      data
    };
  },

  saveSystemParameters(data) {
    return {
      method: 'POST',
      url: '/court-parameter/system',
      data
    };
  },

  getCourtUsageReportSummary(data) {
    return {
      method: 'GET',
      url: '/report/court-usage/sum',
      data
    };
  },

  courtAirState(courtId) {
    return {
      method: 'GET',
      url: `/court/${courtId}/air/state`,
    };
  },

  setCourtAirState(data) {
    return {
      method: 'POST',
      url: `/court/${data.courtId}/air/rpc`,
      data: {
        method: data.method,
        params: data.params
      }
    };
  },

  setCourtAutomataState(data) {
    return {
      method: 'POST',
      url: `/court/${data.courtId}/automata/rpc`,
      data: {
        method: data.method,
        params: data.params
      }
    };
  },

  batchEditUsersStatus(data) {
    return {
      method: 'PATCH',
      url: '/profile/batch/status',
      data
    };
  },

  batchDeleteSubscriptionPlan(data) {
    return {
      method: 'DELETE',
      url: '/subscription-plan/batch',
      data
    };
  },

  getAudioMessages(data) {
    return {
      method: 'GET',
      url: '/court-parameter/audio',
      data
    };
  },

  updateAudioMessage(data) {
    return {
      method: 'POST',
      url: '/court-parameter',
      data
    };
  },

  getCountriesList() {
    return {
      method: 'GET',
      url: '/resource/countries'
    };
  },

  haveCourtActiveGame(id) {
    return {
      method: 'GET',
      url: `/court/${id}/ongoing`
    };
  },
  specialCourtGroupSchedule(data) {
    return {
      method: 'POST',
      url: '/court-group/schedule',
      data
    };
  },
  deleteCourtGroupSpecialDay(id) {
    return {
      method: 'DELETE',
      url: `/court-group/schedule/${id}`,
    };
  },

  getCourtGroupById(id) {
    return {
      method: 'GET',
      url: `/court-group/${id}`,
    };
  },

  getCourtAutomataState(id) {
    return {
      method: 'GET',
      url: `/court/${id}/automata/status`
    };
  },

  setCourtOptimalLights(data) {
    return {
      method: 'POST',
      url: `/court/${data.courtId}/automata/optimal`,
      data: {}
    };
  },

};
