import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { FixedSizeList as List } from 'react-window';
import {
  Paper, Popper, Fade, MenuItem
} from '@material-ui/core';

import { Translate } from 'react-i18nify';
import ItemRenderer from './shared/item-render';
import InputRenderer from './shared/input-render';
import { contains, startWith } from './shared/utils';

class AutoCompleteSelect extends Component {
  state = {
    menu: null
  }

  componentDidUpdate({ value }) {
    const { value: nextValue } = this.props;
    if (nextValue !== value && !nextValue && this.clearSelection) {
      this.clearSelection();
    }
  }

  handleMenu = (e, cb) => {
    this.setState({ menu: e.currentTarget }, cb);
  }

  closeMenu = () => {
    this.setState({ menu: null });
  }

  getValue = (value) => {
    if (typeof value === 'function') {
      return value();
    }
    return value;
  }

  getSuggestions = (value = '') => {
    const { options } = this.props;

    // const existOption = options
    //   .some(suggestion => this.getValue(suggestion.label) === this.getValue(value));

    // if (existOption) {
    //   return options;
    // }


    const startWithOptions = options
      .filter(suggestion => startWith(this.getValue(suggestion.label), this.getValue(value)));

    if (startWithOptions.length) {
      return startWithOptions;
    }
    return options
      .filter(suggestion => contains(this.getValue(suggestion.label), this.getValue(value)));
  };

  getCurrentItem = (item) => {
    const { options } = this.props;
    if (item) {
      const res = options.find(({ value }) => value === item);
      return res;
    }
    return {};
  }

  render() {
    const {
      value,
      name,
      onChange,
      disabled,
      renderListItem,
      error,
      fullWidth,
      notFoundLabel
    } = this.props;

    const { menu } = this.state;

    return (
      <Downshift
        onChange={(e) => {
          onChange({
            target: {
              name,
              value: ((e || {}).value) || ''
            }
          });
          document.activeElement.blur();
        }}

        itemToString={item => (item ? item.label : '')}
        defaultHighlightedIndex={0}
        initialSelectedItem={value}
      >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem,
        }) => {
          const options = this.getSuggestions(inputValue);
          this.clearSelection = clearSelection;

          return (
            <div
              className="container-select"
              onFocus={(e) => {
                this.handleMenu(e);
              }}
            >
              {InputRenderer({
                fullWidth,
                id: `selectInput-${name}`,
                ...getInputProps({
                  onChange: (event) => {
                    event.preventDefault();

                    if (event.target.value === '') {
                      clearSelection();
                    }
                  },
                  onFocus: () => {
                    openMenu();
                  },
                  disabled,
                  value: inputValue || ((this.getCurrentItem(value) || {}).label) || '',
                  error,
                }),
              })}

              <div {...getMenuProps()}>
                {isOpen ? (

                  <Popper
                    open={isOpen}
                    anchorEl={menu}
                    placement="bottom-end"
                    transition
                    className="popper-select"
                    style={{ width: menu ? menu.clientWidth : undefined }}
                    modifiers={{
                      preventOverflow: {
                        enabled: false,
                      }
                    }}
                  >
                    {({ TransitionProps }) => (
                      <Fade
                        {...TransitionProps}
                        id="simple-menu-settings"
                        timeout={0}
                      >
                        <Paper>
                          {options.length ? (
                            <List
                              height={options.length < 5 ? options.length * 46 : 200}
                              itemCount={options.length}
                              itemSize={46}
                              itemData={{
                                options,
                                getItemProps,
                                highlightedIndex,
                                selectedItem,
                                renderListItem,
                              }}
                            >
                              {ItemRenderer}
                            </List>
                          ) : (
                            <MenuItem>
                              {notFoundLabel}
                            </MenuItem>
                          )}
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

AutoCompleteSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  match: PropTypes.arrayOf(PropTypes.string),
  renderListItem: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  notFoundLabel: PropTypes.objectOf(PropTypes.any),
};

AutoCompleteSelect.defaultProps = {
  value: '',
  disabled: false,
  error: false,
  fullWidth: false,
  options: [{}],
  match: ['label'],
  onChange: () => { },
  renderListItem: ({ label = '' }) => (typeof label === 'function' ? label() : label),
  notFoundLabel: <Translate value="desktop.common.nothing_was_found_label" />
};

export default AutoCompleteSelect;
