import { I18n } from 'react-i18nify';

import * as c from '../constants/actions';
import api from '../api';
import createApiAction from '../api/createApiAction';
import history from '../history';
import { roles, PROFILE_STATUSES } from '../constants/utils';
import toast from '../utility/toast-notification';

export const LS_TOKEN_KEY = '__jwtSecurerToken__';

const administrationRoles = [roles.ADMIN, roles.COURT_GROUP_MANAGER, roles.SYSTEM_MANAGER];

const saveToken = (token) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(LS_TOKEN_KEY, token);
    api.setJwtToken(token);
  }
};

export const login = data => createApiAction({
  method: 'login',
  requestAction: c.LOGIN_REQUEST,
  successAction: c.LOGIN_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.status !== 403
      ? response.data.error
      : 'Invalid credentials, wrong email or password';
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });

    dispatch({
      type: c.LOGIN_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
  afterSuccess: (dispatch, { data: { jwt: { token }, role: { level }, profile } }) => {
    if (profile && profile.status !== PROFILE_STATUSES.ACTIVE) {
      const errorMessage = 'This account is inactive';
      toast({
        type: 'error',
        action: I18n.t('desktop.common.system'),
        message: errorMessage
      });
      dispatch({
        type: c.LOGIN_ERROR,
        payload: errorMessage,
      });
    } else if (administrationRoles.includes(level)) {
      saveToken(token);
      history.push('/dashboard');
    } else {
      const errorMessage = 'Insufficient permission';
      toast({
        type: 'error',
        action: I18n.t('desktop.common.system'),
        message: errorMessage
      });

      dispatch({
        type: c.LOGIN_ERROR,
        payload: errorMessage,
      });
    }
  },
});

export const socialLogin = data => createApiAction({
  method: 'socialLogin',
  requestAction: c.SOCIAL_LOGIN_REQUEST,
  successAction: c.SOCIAL_LOGIN_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.status !== 403
      ? response.data.error
      : 'Invalid credentials, wrong email or password';
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });

    dispatch({
      type: c.SOCIAL_LOGIN_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
  afterSuccess: (dispatch, { data: { jwt: { token } } }) => {
    saveToken(token);
    history.push('/dashboard');
  },
});

export const register = data => createApiAction({
  method: 'register',
  requestAction: c.REGISTER_REQUEST,
  successAction: c.REGISTER_SUCCESS,
  errorAction: c.REGISTER_ERROR,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });
    dispatch({
      type: c.REGISTER_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: () => data,
  afterSuccess: (dispatch, { data: { jwt: { token } } }) => {
    saveToken(token);
    history.push('/');
  }
});

export const logout = () => createApiAction({
  method: 'logout',
  requestAction: c.LOGOUT_REQUEST,
  successAction: c.LOGOUT_SUCCESS,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });
    dispatch({
      type: c.LOGOUT_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
  afterSuccess: () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(LS_TOKEN_KEY);
      api.setJwtToken(null);
    }
  }
});


export const forgotPassword = data => createApiAction({
  method: 'forgotPassword',
  requestAction: c.FORGOT_PASSWORD_REQUEST,
  successAction: c.FORGOT_PASSWORD_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });
    dispatch({
      type: c.FORGOT_PASSWORD_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
  afterSuccess: () => toast({
    type: 'success',
    action: 'System',
    message: 'Reset password email was sent',
    options: {
      removeOnHover: true,
      timeOut: 4000
    }
  })
});


export const resetPassword = (data, cb = () => null) => createApiAction({
  method: 'resetPassword',
  requestAction: c.RESET_PASSWORD_REQUEST,
  successAction: c.RESET_PASSWORD_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });
    dispatch({
      type: c.RESET_PASSWORD_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
  afterSuccess: () => {
    toast({
      type: 'success',
      action: 'System',
      message: 'Password was successfully changed'
    });
    cb();
  }
});

export const getUserProfile = ({
  onSuccess = () => null,
  onError = () => null
}) => createApiAction({
  method: 'getUserProfile',
  requestAction: c.GET_USER_PROFILE_REQUEST,
  successAction: c.GET_USER_PROFILE_SUCCESS,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;

    dispatch({
      type: c.GET_USER_PROFILE_ERROR,
      payload: errorMessage,
    });
    onError();
  },
  transformResponse: ({ data }) => data,
  afterSuccess: (dispatch, { data }) => {
    dispatch({
      type: c.LOGIN_SUCCESS,
      payload: data
    });
    onSuccess();
  },
});

export const updateUserProfile = (data, cb = () => null) => createApiAction({
  method: 'updateUserProfile',
  requestAction: c.UPDATE_USER_PROFILE_REQUEST,
  successAction: c.UPDATE_USER_PROFILE_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;
    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });

    dispatch({
      type: c.UPDATE_USER_PROFILE_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
  afterSuccess: () => {
    toast({
      type: 'success',
      action: 'System',
      message: 'Success'
    });
    cb();
  },
});
