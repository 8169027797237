import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { connect } from 'react-redux';

import './styles.scss';

const SidebarItem = ({
  icon,
  title,
  to,
  active,
  accessRoles,
  level,
  ...rest
}) => {
  if (accessRoles.includes(level)) {
    return (
      <Link to={to} className={`item ${active ? 'active-item' : 'not-active'}`} {...rest}>
        <ListItem button alignItems="center">
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText inset primary={title} className="item-label" />
        </ListItem>
      </Link>
    );
  }
  return null;
};

SidebarItem.propTypes = {
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  title: PropTypes.objectOf(PropTypes.any).isRequired,
  icon: PropTypes.node.isRequired,
  accessRoles: PropTypes.arrayOf(PropTypes.number),
  level: PropTypes.number.isRequired
};

SidebarItem.defaultProps = {
  active: false,
  accessRoles: []
};


const mapStateToProps = ({ auth: { data: { role: { level } } } }) => ({
  level,
});

export default connect(mapStateToProps, {})(SidebarItem);