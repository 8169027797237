import React from 'react';
import { List } from '@material-ui/core';
import { Translate } from 'react-i18nify';

import history from '../../history';
import SidebarItem from './sidebar-item';
import './styles.scss';
import logo from '../../media/Logo.svg';
import { roles } from '../../constants/utils';

class Sidebar extends React.Component {
  isElementActive = (path) => {
    const { pathname } = history.location;

    if (pathname.includes(path)) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <List className="sidebar-container">
        <div
          onClick={() => { history.push('/dashboard'); }}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
          className="cursor-pointer"
        >
          <img
            alt="logo"
            src={logo}
            className="logo-sidebar"
          />
        </div>
        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              home
            </i>
          )}
          title={<Translate value="desktop.sidebar.dashboard" />}
          to="dashboard"
          active={this.isElementActive('dashboard')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              account_box
            </i>
          )}
          title={<Translate value="desktop.sidebar.users" />}
          to="users"
          active={this.isElementActive('users')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN]}
          icon={(
            <i className="material-icons">
              people
            </i>
          )}
          title={<Translate value="desktop.sidebar.operators" />}
          to="operators"
          active={this.isElementActive('operators')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              money
            </i>
          )}
          title={<Translate value="desktop.sidebar.subscription_plans" />}
          to="sub-plans"
          active={this.isElementActive('sub-plans')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              group_work
            </i>
          )}
          title={<Translate value="desktop.sidebar.court_groups" />}
          to="court-groups"
          active={this.isElementActive('court-groups')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              meeting_room
            </i>
          )}
          title={<Translate value="desktop.sidebar.courts" />}
          to="courts"
          active={this.isElementActive('courts')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              web_asset
            </i>
          )}
          title={<Translate value="desktop.sidebar.assets" />}
          to="assets"
          active={this.isElementActive('assets')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              insert_drive_file
            </i>
          )}
          title={<Translate value="desktop.sidebar.asset_logs" />}
          to="asset-logs"
          active={this.isElementActive('asset-logs')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              beenhere
            </i>
          )}
          title={<Translate value="desktop.sidebar.reservations" />}
          to="reservations"
          active={this.isElementActive('reservations')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              insert_drive_file
            </i>
          )}
          title={<Translate value="desktop.sidebar.activity_logs" />}
          to="activity-logs"
          active={this.isElementActive('activity-logs')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER, roles.COURT_GROUP_MANAGER]}
          icon={(
            <i className="material-icons">
              settings
            </i>
          )}
          title={<Translate value="desktop.sidebar.parameters" />}
          to="club-params"
          active={this.isElementActive('club-params')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN, roles.SYSTEM_MANAGER]}
          icon={(
            <i className="material-icons">
              message
            </i>
          )}
          title={<Translate value="desktop.sidebar.messages" />}
          to="messages"
          active={this.isElementActive('messages')}
        />

        <SidebarItem
          accessRoles={[roles.ADMIN]}
          icon={(
            <i className="material-icons">
              translate
            </i>
          )}
          title={<Translate value="desktop.sidebar.translations" />}
          to="translations"
          active={this.isElementActive('translations')}
        />


      </List>
    );
  }
}

export default Sidebar;