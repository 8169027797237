import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  isLoading: false,
};

export default createReducer(initialState, {
  [c.UPLOAD_FILE_REQUEST]: state => ({
    ...state,
    isLoading: true,
  }),
  [c.UPLOAD_FILE_SUCCESS]: state => ({
    ...state,
    isLoading: false,
  }),
  [c.UPLOAD_FILE_ERROR]: state => ({
    ...state,
    isLoading: false,
  }),
});
