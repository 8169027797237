import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import auth from './auth';
import operators from './operators';
import subscriptions from './subscriptions';
import courtGroups from './court-groups';
import courts from './courts';
import assets from './assets';
import users from './users';
import translations from './translations';
import activityLogs from './activity-logs';
import paymentMethods from './payment-methods';
import reports from './reports';
import reservations from './reservations';
import messages from './messages';
import parameters from './parameters';
import files from './files';
import utils from './utils';

export const reducers = combineReducers({
  toastr: toastrReducer,
  activityLogs,
  auth,
  assets,
  courtGroups,
  courts,
  files,
  messages,
  operators,
  parameters,
  paymentMethods,
  reports,
  reservations,
  subscriptions,
  translations,
  users,
  utils
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    // Reset the whole app state when user logouts
    return reducers({ translations: state.translations }, action);
  }

  return reducers(state, action);
};


export default rootReducer;
