/* eslint-disable no-await-in-loop */
import { compose, withHandlers } from 'recompose';
import { I18n } from 'react-i18nify';
import { connect } from 'react-redux';

import { uploadFileRequest, uploadFileError, uploadFileSuccess } from '../actions/files';
import api from '../api';
import toast from '../utility/toast-notification';

const extractContentTypeFromUrl = (url) => {
  const awsUrl = new URL(url);
  const params = new URLSearchParams(awsUrl.search);
  return params.get('Content-Type');
};

export default compose(
  connect(null, { uploadFileRequest, uploadFileError, uploadFileSuccess }),
  withHandlers({
    /**
     * This method handles the uploading of files to s3.
     * @param  {[String]} files
     * @returns {Array}
     */
    // eslint-disable-next-line max-len
    uploadFiles: ({ uploadFileRequest, uploadFileError, uploadFileSuccess }) => async (files, checkForMime = false) => {
      try {
        uploadFileRequest();
        const keys = [];
        files.forEach(({ name, type }) => {
          if (type.match(/(mp3|mp4)/gm)) {
            keys.push(name);
          } else {
            const newName = name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}$1`);
            keys.push(newName);
          }
        });

        const { data } = await api.request('POST', '/storage', {
          keys,
        });

        for (let i = 0; i < keys.length; i += 1) {
          const potentialMimeHeaders = {
            'Content-Type': extractContentTypeFromUrl(data[i])
          };
          const requestData = {
            method: 'PUT',
            body: files[i],
          };
          if (checkForMime) {
            requestData.headers = potentialMimeHeaders;
          }
          await fetch(data[i], requestData)
            .then((response) => {
              if (response.ok) {
                toast({
                  type: 'success',
                  action: I18n.t('desktop.common.system'),
                  message: (`File uploaded successfully: ${files[i].name}`),
                  options: {
                    removeOnHover: true,
                    timeOut: 4000
                  }
                });
              } else {
                toast({
                  type: 'error',
                  action: I18n.t('desktop.common.system'),
                  message: (`Failed to upload file: ${files[i].name}`)
                });
                throw response.statusText;
              }
            });
        }
        uploadFileSuccess();
        return data;
      } catch (err) {
        uploadFileError();
        throw err;
      }
    },
  }),
);
