import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'jspdf-autotable';
import 'react-table/react-table.css';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import ApiClient from './api';
import api from './api/methods';
import * as serviceWorker from './serviceWorker';
import App from './app';
import store from './store';
import { getUserProfile } from './actions/auth';
import './global-styles.scss';


const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
      <ReduxToastr
        timeOut={0}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar
      />
    </Provider>,
    document.getElementById('root')
  );
};


if (localStorage.getItem('__jwtSecurerToken__')) {
  store.dispatch(getUserProfile({ onError: render, onSuccess: render }));
} else {
  render();
}


serviceWorker.unregister();

ApiClient.injectApiMethods(api);

if (module.hot) {
  module.hot.accept('./app', () => {
    render();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
