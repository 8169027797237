import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_ACTIVITY_LOGS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_ACTIVITY_LOGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_ACTIVITY_LOGS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
