import mergeObjects from 'merge-objects';

import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {},
  currLang: localStorage.getItem('i18n-locale') || 'en',
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_TRANSLATIONS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_TRANSLATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    isLoading: false,
  }),
  [c.GET_TRANSLATIONS_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.SAVE_TRANSLATION_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.SAVE_TRANSLATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: { ...mergeObjects(state.data, payload) },
    error: null,
    isLoading: false,
  }),
  [c.SAVE_TRANSLATION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CHANGE_CURR_LANGUAGE]: (state, { payload }) => ({
    ...state,
    currLang: payload,
    error: null,
    isLoading: false,
  }),

});
