import { I18n } from 'react-i18nify';

import * as c from '../constants/actions';
import createApiAction from '../api/createApiAction';
import toast from '../utility/toast-notification';

export const getTranslations = data => createApiAction({
  method: 'getTranslations',
  requestAction: c.GET_TRANSLATIONS_REQUEST,
  successAction: c.GET_TRANSLATIONS_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;

    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });

    dispatch({
      type: c.GET_TRANSLATIONS_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: ({ data }) => data,
});

export const saveTranslation = (data, cb = () => null) => createApiAction({
  method: 'saveTranslation',
  requestAction: c.SAVE_TRANSLATION_REQUEST,
  successAction: c.SAVE_TRANSLATION_SUCCESS,
  params: data,
  errorHandler(dispatch, { response }) {
    const errorMessage = response.data.error;

    toast({
      type: 'error',
      action: I18n.t('desktop.common.system'),
      message: errorMessage
    });

    dispatch({
      type: c.SAVE_TRANSLATION_ERROR,
      payload: errorMessage,
    });
  },
  transformResponse: () => ({ [data.lang]: { ...data.content.translations } }),
  afterSuccess: () => {
    cb();
    toast({
      type: 'success',
      action: I18n.t('desktop.common.system'),
      message: I18n.t('desktop.success_messages.translation_edited_successfully')
    });
  },
});

export const handleCurrentLanguage = payload => ({
  type: c.CHANGE_CURR_LANGUAGE,
  payload
});
