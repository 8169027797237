import React from 'react';
import PropTypes from 'prop-types';
import { Hidden, Drawer } from '@material-ui/core';
import { connect } from 'react-redux';

import Sidebar from '../components/sidebar';
import Navbar from '../components/navbar';
import { withLoader } from '../hocs';
import './styles.scss';

class MainLayout extends React.Component {
  state = {
    isSidebarOpen: false,
  }

  handleSidebar = () => {
    this.setState(({ isSidebarOpen }) => ({
      isSidebarOpen: !isSidebarOpen
    }));
  }

  render() {
    const { children, ...rest } = this.props;
    const { isSidebarOpen } = this.state;

    return (
      <div className="d-flex w-100 main">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={isSidebarOpen}
            onClose={this.handleSidebar}
          >
            <Sidebar {...rest} />
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            className="drawer"
            open
          >
            <Sidebar {...rest} />
          </Drawer>
        </Hidden>
        <div className="children">
          <div className="container">
            <Navbar isSidebarOpen={isSidebarOpen} handleSidebar={this.handleSidebar} {...rest} />
            {children}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ files }) => ({
  isLoading: files.isLoading
});

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default connect(mapStateToProps, {})(withLoader(MainLayout));
