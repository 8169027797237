import createReducer from './utils/createReducer';
import * as c from '../constants/actions';

const initialState = {
  data: {
    revenue: {
      data: [],
      summary: 0
    },
    payment: {
      summary: 0,
      chart: {},
      table: {}
    },
    courtUsage: {
      summary: 0,
      table: [],
      chart: []
    },
    courtUtilization: {
      summary: 0,
      table: [],
      chart: []
    },
    topPlayers: {
      summary: 0,
      data: []
    }
  },
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_REVENUE_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_REVENUE_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      revenue: {
        ...state.data.revenue,
        data: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_REVENUE_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_REVENUE_REPORT_SUMMARY_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_REVENUE_REPORT_SUMMARY_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      revenue: {
        ...state.data.revenue,
        summary: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_REVENUE_REPORT_SUMMARY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_USAGE_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_USAGE_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      courtUsage: {
        ...state.data.courtUsage,
        chart: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_USAGE_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_USAGE_REPORT_SUMMARY_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_USAGE_REPORT_SUMMARY_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      courtUsage: {
        ...state.data.courtUsage,
        summary: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_USAGE_REPORT_SUMMARY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_USAGE_TABLE_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_USAGE_TABLE_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      courtUsage: {
        ...state.data.courtUsage,
        table: payload,
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_USAGE_TABLE_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_TOP_PLAYERS_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_TOP_PLAYERS_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      topPlayers: {
        ...state.data.topPlayers,
        data: payload,
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_TOP_PLAYERS_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_UTILIZATION_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_UTILIZATION_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      courtUtilization: {
        ...state.data.courtUtilization,
        chart: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_UTILIZATION_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_UTILIZATION_REPORT_SUMMARY_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_UTILIZATION_REPORT_SUMMARY_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      courtUtilization: {
        ...state.data.courtUtilization,
        summary: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_UTILIZATION_REPORT_SUMMARY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_COURT_UTILIZATION_TABLE_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_COURT_UTILIZATION_TABLE_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      courtUtilization: {
        ...state.data.courtUtilization,
        table: payload,
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_COURT_UTILIZATION_TABLE_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_PAYMENT_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_PAYMENT_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      payment: {
        ...state.data.payment,
        chart: payload
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_PAYMENT_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_PAYMENT_TABLE_REPORT_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_PAYMENT_TABLE_REPORT_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      payment: {
        ...state.data.payment,
        table: payload,
      }
    },
    error: null,
    isLoading: false,
  }),
  [c.GET_PAYMENT_TABLE_REPORT_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
