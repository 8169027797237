import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const withLoader = Component => (props) => {
  const { isLoading } = props;
  const tempComp = () => (
    <>
      {isLoading && (<div className="loader"><CircularProgress color="primary" size={75} /></div>)}
      <Component {...props} />
    </>
  );
  tempComp.propTypes = {
    isLoading: PropTypes.bool.isRequired
  };
  return tempComp();
};


export default withLoader;
